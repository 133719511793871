import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { JsonBaseService } from './abstracts/json-base.service';
import { Constants } from '../models';

@Injectable({ providedIn: 'root' })
export class ConstantsService extends JsonBaseService<Constants> {
  protected jsonFileName = 'constants.json';

  constants$: BehaviorSubject<Constants>;

  constructor(httpClient: HttpClient
    // , @Inject('BASE_URL') baseUrl: string, @Inject('API_URL') apiUrl: string
    ) {
    super(httpClient);
    // super(httpClient, baseUrl);

    // Load constants file
    this.constants$ = new BehaviorSubject(null);
    this.loadSingle().subscribe();
  }

  loadSingle(): Observable<Constants> {
    return super.loadSingle()
      .pipe(
        first(),
        tap(c => this.constants$.next(c))
      );
  }
}