import { Component, Input } from '@angular/core';

@Component({
  selector: 'cbws-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
  @Input()
  height: number;

  @Input()
  width: number;

  @Input()
  contrasted: boolean;

  constructor() {
    this.height = 50;
    this.width = 180;
  }
}
