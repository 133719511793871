import { Directive, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

/**
 * Abstract class for the component that displays a spinner.
 */
@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class SpinnerBaseComponent {
  /**
   * Progress spinner color.
   */
  @Input()
  color: ThemePalette = 'primary';

  /**
   * Progress spinner diameter.
   */
  @Input()
  diameter = 40;

  /**
   * Progress spinner mode.
   */
  @Input()
  mode: ProgressSpinnerMode = 'indeterminate';

  /**
   * Gets the spinner max height
   */
  get maxHeight(): string {
    return `${this.diameter}px`;//`calc(${this.diameter}px * 1.5)`;
  }
}
