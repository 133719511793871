import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LogoComponent } from './logo/logo.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormGroupComponent } from './form-group/form-group.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { HttpErrorComponent } from './http-error/http-error.component';
import { HttpSuccessComponent } from './http-success/http-success.component';
import { CardColorComponent } from './card-color/card-color.component';
import { ErrorsComponent } from './errors/errors.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule  } from '@angular/material/radio';
import { BuildingComponent } from './building/building.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormCardComponent } from './form-card/form-card.component';
import { FormCardHeaderComponent } from './form-card-header/form-card-header.component';
import { FormCardContentComponent } from './form-card-content/form-card-content.component';
import { InputErrorComponent } from './input-error/input-error.component';
import { ApiErrorsComponent } from './api-errors/api-errors.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatGridList  } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { IconEditButtonComponent } from './icon-edit-button/icon-edit-button.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { TableEmptyComponent } from './table-empty/table-empty.component';
import { TableSpinnerComponent } from './table-spinner/table-spinner.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { FilterService } from 'src/app/services/filter.service';
import { SimpleFilterComponent } from './simple-filter/simple-filter.component';
import { CheckboxFilterComponent } from './checkbox-filter/checkbox-filter.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_FORMATS } from 'src/app/sections/administration-section/carrier/job-offer-form/administration-job-offer-form.component';
import { IconDownButtonComponent } from './icon-down-button/icon-down-button.component';
import { IconTimesButtonComponent } from './icon-times-button/icon-times-button.component';
import { IconUpButtonComponent } from './icon-up-button/icon-up-button.component';
import { IconDeleteButtonComponent } from './icon-delete-button/icon-delete-button.component';
import { ImageTitleComponent } from './image-title/image-title.component';


const routes: Routes = [];

@NgModule({
  declarations: [
    LogoComponent,
    FormGroupComponent,
    LoadingSpinnerComponent,
    HttpErrorComponent,
    HttpSuccessComponent,
    CardColorComponent,
    ErrorsComponent,
    BuildingComponent,
    ContactFormComponent,
    FormCardComponent,
    FormCardHeaderComponent,
    FormCardContentComponent,
    InputErrorComponent,
    ApiErrorsComponent,
    DialogConfirmComponent,
    IconButtonComponent,
    IconDeleteButtonComponent,
    IconDownButtonComponent,
    IconEditButtonComponent,
    IconTimesButtonComponent,
    IconUpButtonComponent,
    TableEmptyComponent,
    TableSpinnerComponent,
    SimpleFilterComponent,
    CheckboxFilterComponent,
    ImageTitleComponent,
  ],
  exports: [
    AngularEditorModule ,
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MaterialFileInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDialogModule,
    MatListModule,
    MatButtonModule,
    MatRadioModule,
    FlexLayoutModule,
    MatTableModule,
    MatSidenavModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatTabsModule,
    MatAutocompleteModule,

    LogoComponent,
    FormGroupComponent,
    LoadingSpinnerComponent,
    HttpErrorComponent,
    HttpSuccessComponent,
    CardColorComponent,
    ErrorsComponent,
    BuildingComponent,
    ContactFormComponent,
    FormCardComponent,
    FormCardHeaderComponent,
    FormCardContentComponent,
    InputErrorComponent,
    ApiErrorsComponent,
    DialogConfirmComponent,
    IconButtonComponent,
    IconDeleteButtonComponent,
    IconDownButtonComponent,
    IconEditButtonComponent,
    IconTimesButtonComponent,
    IconUpButtonComponent,
    TableEmptyComponent,
    TableSpinnerComponent,
    SimpleFilterComponent,
    CheckboxFilterComponent,
    ImageTitleComponent,
  ],
  imports: [
    AngularEditorModule ,
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FontAwesomeModule,
    MatFormFieldModule,
    MatInputModule,
    MaterialFileInputModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDialogModule,
    MatListModule,
    MatGridListModule,
    MatButtonModule,
    MatRadioModule,
    FlexLayoutModule,
    MatTableModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatTabsModule,
  ],
  providers: [
    FilterService,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class CommonComponentsModule { }