import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ContactForm, FormResult } from 'src/app/models';
import { ContactService } from 'src/app/services';

@Component({
  selector: 'cbws-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  form: FormGroup;
  result: FormResult;
  requesting: boolean;

  get name(): AbstractControl {
    return this.form.get('name');
  }
  get email(): AbstractControl {
    return this.form.get('email');
  }
  get phone(): AbstractControl {
    return this.form.get('phone');
  }
  get company(): AbstractControl {
    return this.form.get('company');
  }
  get occupation(): AbstractControl {
    return this.form.get('occupation');
  }
  get subject(): AbstractControl {
    return this.form.get('subject');
  }
  get message(): AbstractControl {
    return this.form.get('message');
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly contactService: ContactService) { }

  ngOnInit(): void {
    this.buildForm();
    this.requesting = false;
  }

  submitMessage(form: FormGroup): void {

      this.requesting = true;

      grecaptcha.execute('6Lf9zlIaAAAAAMFxLHc2UdcqbzdmERUNzI9etCSO', {action: 'submit'})
      .then((token: string) => {
        if (form.invalid) {
          form.markAllAsTouched();
          return;
        }

        const value = this.getFormValue(form);
        value.recaptcha = token;

        this.contactService.post(value)
          .subscribe(
            () => {
              this.result = { success: true };
              this.requesting = false;
              form.reset();
            },
            (err: HttpErrorResponse) =>{
              this.result = {
                success: false,
                errors: [err.error]
              };
              this.requesting = false;
            }
              
          );
      },
      (err: any) => console.error(err));
  }

  private buildForm() {
    this.form = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(120)]],
      email: [null, [Validators.required, Validators.email, Validators.maxLength(255)]],
      phone: [null, [Validators.maxLength(15)]],
      company: [null, [Validators.maxLength(255)]],
      occupation: [null, [Validators.maxLength(255)]],
      subject: [null, [Validators.required, Validators.maxLength(255)]],
      message: [null, [Validators.required]]
    });
  }

  private getFormValue(form: FormGroup): ContactForm {
    return form.value as ContactForm;
  }
}
