import { HttpErrorResponse } from '@angular/common/http';
import { Directive, OnInit } from '@angular/core';
import { NEVER, Observable } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

import { Disposable } from './disposable';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class ExternalData<T> extends Disposable implements OnInit {
  data: Array<T>;

  ngOnInit(): void {
    this.loadData()
      .pipe(
        first(),
        catchError(this.handleError)
      )
      .subscribe(data => this.data = data );
  }

  protected handleError(error: HttpErrorResponse): Observable<never> {
    console.error(error.message);
    this.data = [];
    return NEVER;
  }

  protected abstract loadData(): Observable<Array<T>>;
}
