import { Directive, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize, first } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { RequestingBaseComponent } from './requesting-base.component';

/**
 * Abstract class for the component that uses and displays a FormGroup.
 */
@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class FormBaseComponent<TModel>
  extends RequestingBaseComponent
  implements OnInit {
  /**
   * Variable that hold the form data retrieved and binded to the form as default value.
   */
  formData: TModel;

  /**
   * Component form group.
   */
  form: FormGroup;

  /**
   * Maximum file size authorized
   */
  maxFileSize = 2000000;

  /**
   * Initializes the form and its data.
   */
  ngOnInit(): void {
    this.retrieveFormData()
      .pipe(
        first(),
        finalize(() => this.setRequestingState(false)))
      .subscribe((data: TModel) => {
        this.formData = data;
        this.form = this.buildForm(data);
      });
  }

  /**
   * Retrieves the value of the provided form and tries to cast it to TModel.
   * @param form the form to get value from
   */
  getFormValue(form: FormGroup): TModel {
    if (form == null) {
      throw new Error('form cannot be undefined.');
    }

    return form.value as TModel;
  }

  /**
   * Handles the reset form event.
   * @param form the form that must be resetted
   * @param data default data to be set as default form value
   */
  reset(form: FormGroup, data?: TModel): void {
    if (data != null) {
      form.reset(data);
    } else {
      form.reset();
    }

    // Remove requesting/success/error states
    this.setRequestingState(false);
    this.setError(null);
    this.setSuccess(null);

    // Call user code
    this.onReset();
  }

  /**
   * Handles the submit form event.
   * @param form the form that is submitted
   */
  submit(form: FormGroup): void {
    // Check if form is valid
    if (!this.validateForm(form)) {
      if (!environment.production) {
        console.warn('FORM INVALID');
      }
      return;
    }

    // Change requesting state
    this.setRequestingState(true);

    // Hide previous error/success
    this.setError(null);
    this.setSuccess(null);

    // Call user code
    this.onSubmit(this.getFormValue(form));
  }

  /**
   * Creates the form group that will be used.
   */
  protected abstract buildForm(data?: TModel): FormGroup;

  /**
   * Callback used when the form is resetted.
   */
  protected abstract onReset(): void;

  /**
   * Callback used when the form is submitted.
   * @param data the form data that is submitted
   */
  protected abstract onSubmit(data: TModel): any;

  /**
   * Retrieves the form default data.
   */
  protected abstract retrieveFormData(): Observable<TModel>;

  /**
   * Sets the requesting state.
   * @param requesting the value of the requesting state to set
   */
  protected setRequestingState(requesting: boolean): void {
    super.setRequestingState(requesting);

    // TODO: find a way to disable/enable only the form controls that are not initialized as 'disabled'
    // if (requesting) {
    //   this.form.disable();
    // } else {
    //   this.form.enable();
    // }
  }

  /**
   * Valides and trigger all errors of the form.
   * @param form the form to be validated
   */
  protected validateForm(form: FormGroup): boolean {
    if (form.invalid) {
      form.markAllAsTouched();
      return false;
    }

    return true;
  }
}
