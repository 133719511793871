import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Abstract class that completes all subscribed observables on destroy.
 */
@Directive()
export abstract class DisposableObservableDirective implements OnDestroy {
  /**
   * Flag that prevents the class to be disposed twice.
   */
  private disposing: boolean;

  /**
   * Subject used to trigger and complete subscribed observables.
   */
  protected sub$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.dispose();
  }

  /**
   * Completes all subscribed observables.
   */
  dispose(): void {
    if (!this.disposing) {
      this.disposing = true;

      this.sub$.next();
      this.sub$.complete();
      this.sub$ = null;
    }
  }
}
