import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RoleType } from '../enums/role-type.enum';
import { RequestPasswordForm, ResetPasswordForm, UserTwoFactorAuth } from '../models';
import { UserCredentials } from '../models/login/user-credentials';
import { UserLogged } from '../models/login/user-logged';
import { ApiBaseService } from './abstracts/api-base.service';

@Injectable()
export class AuthService extends ApiBaseService {
  private loggedSrc: BehaviorSubject<boolean>;
  private has2faSrc: BehaviorSubject<boolean>;
  private userKey = 'user';
  protected controllerName = 'authentication';

  /**
   * Gets the flag indicating if the current user is logged in
   */
  logged$: Observable<boolean>;

  /**
   * Gets the flag indicating if the current has 2fa enabled
   */
  has2fa$: Observable<boolean>;

  /**
   * Gets the stored user
   */
  get user(): UserLogged {
    const user = localStorage.getItem(this.userKey);
    return JSON.parse(user) as UserLogged;
  }

  /**
   * Gets the stored role value
   */
  get role(): RoleType {
    return this.user != null ? this.user.role : null;
  }

  /**
   * AuthService constructor
   * @param http Angular's HTTP service
   */
  constructor(private readonly http: HttpClient) {
    super(http);

    this.loggedSrc = new BehaviorSubject<boolean>(!!this.user);
    this.logged$ = this.loggedSrc.asObservable();

    this.has2faSrc = new BehaviorSubject<boolean>(
      !!this.user && this.user.hasTwoFactor
    );
    this.has2fa$ = this.has2faSrc.asObservable();
  }

  /**
   * Submits the provided credentials to server
   * @param credentials user credentials to validate
   */
  login(credentials: UserCredentials): Observable<UserLogged> {
    return this.httpClient
      .post<UserLogged>(this.getUrl('login'), credentials)
      .pipe(
        tap((user: UserLogged) => {
          // Clear storage before save data
          localStorage.clear();

          // Store user data
          this.storeUserData(user);
          this.toggleUser2fa(user.hasTwoFactor);
          this.loggedSrc.next(true);
        })
      );
  }

  /**
   * Submits the provided authenticator code to logs user in
   * @param formData user two factor code
   */
  loginTwoFactor(formData: UserTwoFactorAuth): Observable<void> {
    return this.http
      .post<void>(this.getUrl('loginTwoFactor'), formData)
      .pipe(tap(() => this.toggleUser2fa(true)));
  }

  /**
   * Submits the provided recovery code to logs user in
   * @param formData user recovery code
   */
  loginRecoveryCode(formData: UserTwoFactorAuth): Observable<void> {
    return this.http.post<void>(
      this.getUrl('loginRecoveryCode'),
      formData
    );
  }

  /**
   * Logs the current user out
   */
  logout(): void {
    localStorage.clear();
    this.loggedSrc.next(false);
    this.http.post<void>(this.getUrl('logout'), null).subscribe();
  }

  /**
   * Submits the request reset password to server
   * @param formData user email
   */
  requestPasswordReset(formData: RequestPasswordForm): Observable<void> {
    return this.http.post<void>('api/user/request-reset-password', formData);
  }

  /**
   * Submits and resets user password on server
   * @param formData user new password form data
   */
  resetPassword(formData: ResetPasswordForm): Observable<void> {
    return this.http.post<void>('api/user/reset-password', formData);
  }

  /**
   * Stores and notifies that the current user has enabled 2fa
   */
  add2fa(): void {
    this.toggleUser2fa(true);
  }

  /**
   * Stores and notifies that the current has disabled 2fa
   */
  remove2fa(): void {
    this.toggleUser2fa(false);
  }

  /**
   * Stores user data into local storage
   * @param data user data to be stored
   */
  private storeUserData(data: UserLogged): void {
    localStorage.setItem(this.userKey, JSON.stringify(data));
  }

  /**
   * Stores and notifies 2fa state changes
   * @param has2fa 2fa state
   */
  private toggleUser2fa(has2fa: boolean): void {
    const user = this.user;
    user.hasTwoFactor = has2fa;
    this.storeUserData(user);
    this.has2faSrc.next(has2fa);
  }
}
