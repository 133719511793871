<div fxFlexFill fxLayoutAlign="center center">
  <cbws-form-card>
    <!-- Header -->
    <cbws-form-card-header>
      <h3 class="mat-headline mb-0 ml-5">Réinitialiser le mot de passe</h3>
      <img
        class="cbws-form-card-header-logo"
        src="assets/images/logo2.png"
      />
    </cbws-form-card-header>

    <!-- Content -->
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <cbws-form-card-content>
        <!-- Email -->
        <mat-form-field fxFlex="100%">
          <mat-label>Email</mat-label>
          <input
            type="email"
            matInput
            formControlName="email"
            placeholder="Email"
          />
          <mat-error *ngIf="email.invalid">
            <cbws-input-error [control]="email" label="Email"></cbws-input-error>
          </mat-error>
        </mat-form-field>

        <!-- Errors -->
        <cbws-api-errors [error]="error" fxFlex="100%"></cbws-api-errors>

        <!-- Success -->
        <p class="alert-success" *ngIf="success" fxFlex="100%">
          {{ success }}
        </p>

        <!-- Actions -->
        <cbws-form-card-action fxFlex="100%">
          <a [routerLink]="['/login']" class="btn btn-light" fxFlex>
            Annuler
          </a>
          <button type="submit" class="btn btn-primary" fxFlex>
            Réinitialiser
          </button>
        </cbws-form-card-action>
      </cbws-form-card-content>
    </form>
  </cbws-form-card>
</div>
