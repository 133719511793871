import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Recognizer } from '../models';
import { ApiBaseService } from './abstracts/api-base.service';

/**
 * Service used to call Entities API controller.
 */
@Injectable({ providedIn: 'root' })
export class RecognizerService extends ApiBaseService {
  protected controllerName = 'recognizer';

  loadFile(formData: Recognizer, controllerPath: string): Observable<any> {
    const data = this.createFormData(formData);

    return this.httpClient.post<any>(`${this.getUrl(controllerPath)}`, data);
  }
}
