import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ErrorContent } from 'src/app/models';
import { ErrorService } from 'src/app/services';


/**
 * Component used to display some errors.
 */
@Component({
  selector: 'cbws-http-error',
  templateUrl: './http-error.component.html',
  styleUrls: ['./http-error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HttpErrorComponent implements OnChanges {
  /**
   * Error received.
   */
  @Input()
  httpError: any;

  /**
   * Multiple errors to be displayed.
   */
  errors: Array<ErrorContent>;

  /**
   * Flag indicating if there are some errors to display.
   */
  get hasError(): boolean {
    return this.errors != null && this.errors.length > 0;
  }

  /**
   * HttpErrorComponent constructor.
   * @param errorService error handler service
   */
  constructor(private readonly errorService: ErrorService) { }

  /**
   * Listens for the input changes.
   * @param changes input changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.httpError
      && changes.httpError.currentValue !== changes.httpError.previousValue) {
      this.errors = this.errorService.handleHttpError(changes.httpError.currentValue);
    }
  }
}
