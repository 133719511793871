import { Component, ViewEncapsulation } from '@angular/core';
import { SpinnerBaseComponent } from '../../core';


/**
 * Component that displays a loading spinner.
 */
@Component({
  selector: 'cbws-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingSpinnerComponent extends SpinnerBaseComponent { }
