<div fxFlexFill fxLayoutAlign="center center">
  <cbws-form-card>
    <!-- Header -->
    <cbws-form-card-header>
      <img
        class="cbws-form-card-header-logo-login"
        src="assets/images/logo2.png"
      />
    </cbws-form-card-header>

    <!-- Content -->
    <div class="position-relative">
      <!-- Spinner -->
      <cbws-loading-spinner [diameter]="50" *ngIf="requesting"></cbws-loading-spinner>

      <ng-content></ng-content>
    </div>
  </cbws-form-card>
</div>
