<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>
<body>
  
</body>
</html>
<header
  class="cbws-navbar"
  [class.cbws-header-sticky]="isSticky"
  [class.cbws-header-expanded]="isExpanded"
>
  <nav
    class="navbar navbar-expand-md p-0"
  >
    <div class="container">
      <a
        class="navbar-brand"routerLink="/"
        title="Retour à la page d'accueil"
        i18n-title="header home link|Link for the home page of the header"
      >
        <cbws-logo></cbws-logo>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target=".navbar-collapse"
        aria-label="Basculer la barre de navigation"
        i18n-aria-label="
          header collapse menu button|Aria label for the collapse menu button
        "
        [attr.aria-expanded]="isExpanded"
        (click)="toggle()"
      >
        <fa-icon [icon]="faBars"></fa-icon>
      </button>
      <div
        class="collapse navbar-collapse flex-md-row-reverse"
        [class.show]="isExpanded"
      >
        <ul class="navbar-nav flex-grow align-items-center">


          <li class="nav-item" [class.active]="getFragment('/home')">
            <a class="nav-link" 
            routerLink="/home" 
            title="Accueil" 
            (click)="toggle()"> Accueil </a>
          </li>
          <!----- New News ----->
        <!-- <li class="nav-item" [class.active]="getFragment('/home/news')">
          <a class="nav-link" 
          routerLink="/home/news" 
          title="NEWS" 
          (click)="toggle()"> ACTUALITÉS </a>
        </li> -->
      
           <!----- Articles  ----->
           <li class="nav-item" [class.active]="getFragment('/home/articles')">
            <a class="nav-link" 
            routerLink="/home/articles" 
            title="NEWS" 
            (click)="toggle()"> NEWS </a>
          </li>


          <li class="nav-item" [class.active]="getFragment('/home/usecases')">
            <a class="nav-link" 
            routerLink="/home/usecases" 
            title="Expertise" 
            (click)="toggle()"> EXPERTISE </a>
          </li>

        <!----- Job offer ----->
          <li class="nav-item" [class.active]="getFragment('/home/carrier')">
            <a class="nav-link" 
            title="Carrière" 
            href="https://castle-bee-data-cloud-cyber-foundry.welcomekit.co/"
            > Rejoignez-nous </a>
          </li>


        

        <!----- Linkedin ----->
        <li class="nav-item">
          <a href="https://www.linkedin.com/company/castlebee" target="_blank" title="Linkedin"><i class="fab fa-linkedin"></i></a>
        </li>
        <!-- Welcome to the jungle -->
        <li class="nav-item">
            <a href="https://www.welcometothejungle.com/fr/companies/castle-bee-data-cloud-cyber-foundry" target="_blank" style="position:relative;top:-1px;" title="Welcome to the jungle">
              <img src="/assets/images/Wjungle-icon.png" style="width:16px ;">
            </a>
        </li>

          <li class="nav-item" [class.active]="getFragment('/home/tests')">
            <a class="nav-link" 
            routerLink="/home/tests" 
            title="Testez-nous" 
            (click)="toggle()"> Testez notre OCR </a>
          </li>
        
          <!-- <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/login"
              title="Connexion"
            >
              <fa-icon [icon]="faPowerOff"></fa-icon>
            </a>
          </li> -->
        </ul>
      </div>
      
    </div>
  </nav>
</header>
