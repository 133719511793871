import { Component, Input } from '@angular/core';
import { ErrorContent } from 'src/app/models';


@Component({
  selector: 'cbws-errors',
  templateUrl: './errors.component.html'
})
export class ErrorsComponent {
  /**
   * Errors to display
   */
  @Input()
  errors: Array<ErrorContent>;

  /**
   * Flag indicating if there is any error to display
   */
  get hasError(): boolean {
    return this.errors != null && this.errors.length > 0;
  }
}
