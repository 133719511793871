import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { LoginFormViewBase } from 'src/app/models';
import { UserCredentials } from 'src/app/models/login/user-credentials';
import { UserLogged } from 'src/app/models/login/user-logged';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'cbws-login',
  templateUrl: './login.component.html'
})
export class LoginComponent extends LoginFormViewBase<UserCredentials>
  implements OnInit, OnDestroy {
  /**
   * Gets the username form control
   */
  get username(): AbstractControl {
    return this.form.get('username');
  }

  /**
   * Gets the password form control
   */
  get password(): AbstractControl {
    return this.form.get('password');
  }

  /**
   * LoginComponent constructor
   * @param authService Authentication service
   * @param fb Angular's form builder
   * @param route Angular's current route
   * @param router Angular's router
   * @param title Angular's title service
   */
  constructor(
    private readonly authService: AuthService,
    private readonly fb: FormBuilder,
    readonly route: ActivatedRoute,
    private readonly router: Router,
    readonly title: Title
  ) {
    super(route, title, 'Login');
  }

  /**
   * Initializes the component
   */
  ngOnInit(): void {
    this.init();
  }

  

  /**
   * Checks and submits the login form
   */
  onLogin(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    }

    this.resetBeforeRequest();
    const credentials = this.getFormData();

    this.authService
      .login(credentials)
      .pipe(finalize(() => this.finalize()))
      .subscribe(
        (user: UserLogged) => {
          if (user.hasTwoFactor && user.twoFactorDisabled) {
            this.redirectToUserHomePage(this.authService, this.router);
          } else {
            this.router.navigate(['/administration']);
          }
        },
        (err: HttpErrorResponse) => this.catchError(err)
      );
  }

  /**
   * Creates the login forms
   */
  protected buildForm(): void {
    this.form = this.fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required]
    });
  }
}
