/**
 * Helper for the Date type.
 */
export class DateHelper {
  /**
   * Returns whether the provided value is a Date instance.
   * @param value value to check
   */
  static isDate(value: any): boolean {
    return value instanceof Date ||
      Object.prototype.toString.call(value) === '[object Date]';
  }
}
