import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export abstract class ServiceBase {
  constructor(
    protected readonly httpClient: HttpClient
    // @Inject('BASE_URL') protected readonly baseUrl: string
    ) {
  }

  /**
   * Creates http params for the given object
   * @param obj object to convert into HttpParams
   * @param params base HttpParams to be completed
   */
   protected createObjectParams(obj: any, params?: HttpParams): HttpParams {
    if (params == null) {
      params = new HttpParams();
    }

    Object.keys(obj).forEach((k: string) => {
      const value = obj[k];
      if (value != null) {
        if (value.toISOString instanceof Function) {
          params = params.set(k, value.toISOString());
        } else {
          params = params.set(k, value.toString());
        }
      }
    });

    return params;
  }
}