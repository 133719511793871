<form class="cbws-contact-form" [formGroup]="form" (ngSubmit)="submitMessage(form)">
  <div class="form-row">
    <div class="form-group col-12">
      <label
        for="cbwsContactName"
        i18n="contact form name|Label for the name of contact form"
      >
        Nom <span class="required">*</span>
      </label>
      <input
        type="text"
        class="form-control"
        name="name"
        id="cbwsContactName"
        placeholder="Prénom Nom"
        i18n-placeholder="
          contact form name placeholder|Placeholder for the name of
          contact form
        "
        maxlength="120"
        formControlName="name"
      />
      <div class="invalid-feedback" *ngIf="name.invalid && name.touched">
        <span
          *ngIf="name.getError('required')"
          i18n="
            contact form name required error|Required error for the name
            of contact form
          "
        >
          Veuillez renseigner votre prénom et nom.
        </span>
        <span
          *ngIf="name.getError('maxlength')"
          i18n="
            contact form name max length error|Max length error for the
            name of contact form
          "
        >
          Votre nom ne doit pas dépasser 120 caractères.
        </span>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="cbwsContactEmail"
        i18n="contact form email|Label for the email of contact form"
        title="Email"
      >
        Email <span class="required">*</span>
      </label>
      <input
        type="email"
        class="form-control"
        name="email"
        id="cbwsContactEmail"
        placeholder="mon.email@messagerie.fr"
        i18n-placeholder="
          contact form email placeholder|Placeholder for the email of
          contact form
        "
        email
        maxlength="255"
        formControlName="email"
        title="Email"
      />
      <div
        class="invalid-feedback"
        *ngIf="email.invalid && email.touched"
      >
        <span
          *ngIf="email.getError('required')"
          i18n="
            contact form email required error|Required error for the email
            of contact form
          "
        >
          Veuillez renseigner votre email.
        </span>
        <span
          *ngIf="email.getError('email')"
          i18n="
            contact form email email error|Email error for the email of
            contact form
          "
        >
          Votre email est invalide.
        </span>
        <span
          *ngIf="email.getError('maxlength')"
          i18n="
            contact form email max length error|Max length error for the
            email of contact form
          "
        >
          Votre email ne doit pas dépasser 255 caractères.
        </span>
      </div>
    </div>

    <div class="form-group col-12 col-md-6">
      <label
        for="cbwsContactPhone"
        i18n="contact form phone|Label for the phone of contact form"
        title="Phone"
      >
        Téléphone
      </label>
      <input
        type="tel"
        class="form-control"
        name="phone"
        id="cbwsContactPhone"
        placeholder="01 02 03 04 05"
        i18n-placeholder="
          contact form phone placeholder|Placeholder for the phone of
          contact form
        "
        maxlength="15"
        formControlName="phone"
        title="Phone"
      />
      <div
        class="invalid-feedback"
        *ngIf="phone.invalid && phone.touched"
      >
        <span
          *ngIf="phone.getError('maxlength')"
          i18n="
            contact form phone max length error|Max length error for the
            phone of contact form
          "
        >
          Votre numéro de téléphone ne doit pas dépasser 15 caractères.
        </span>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="cbwsContactCompany"
        i18n="contact form company|Label for the company of contact form"
        title="Entreprise"
      >
        Entreprise
      </label>
      <input
        type="text"
        class="form-control"
        name="company"
        id="cbwsContactCompany"
        placeholder="Castle Frog"
        i18n-placeholder="
          contact form company placeholder|Placeholder for the company of
          contact form
        "
        maxlength="255"
        formControlName="company"
        title="Company"
      />
      <div
        class="invalid-feedback"
        *ngIf="company.invalid && company.touched"
      >
        <span
          *ngIf="company.getError('maxlength')"
          i18n="
            contact form company max length error|Max length error for the
            company of contact form
          "
        >
          Votre entreprise ne doit pas dépasser 255 caractères.
        </span>
      </div>
    </div>

    <div class="form-group col-12 col-md-6">
      <label
        for="cbwsContactOccupation"
        i18n="
          contact form occupation|Label for the occupation of contact form
        "
        title="Occupation"
      >
        Poste
      </label>
      <input
        type="text"
        class="form-control"
        name="occupation"
        id="cbwsContactOccupation"
        placeholder="CEO"
        i18n-placeholder="
          contact form occupation placeholder|Placeholder for the
          occupation of contact form
        "
        maxlength="255"
        formControlName="occupation"
        title="Occupation"
      />
      <div
        class="invalid-feedback"
        *ngIf="occupation.invalid && occupation.touched"
      >
        <span
          *ngIf="occupation.getError('maxlength')"
          i18n="
            contact form occupation max length error|Max length error for
            the occupation of contact form
          "
        >
          Votre poste ne doit pas dépasser 255 caractères.
        </span>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12">
      <label
        for="cbwsContactSubject"
        i18n="contact form subject|Label for the subject of contact form"
        title="Subject"
      >
        Sujet <span class="required">*</span>
      </label>
      <input
        type="text"
        class="form-control"
        name="subject"
        id="cbwsContactSubject"
        placeholder="Devis audit, projet R&D, etc..."
        i18n-placeholder="
          contact form subject placeholder|Placeholder for the subject of
          contact form
        "
        maxlength="255"
        formControlName="subject"
        title="Subject"
      />
      <div
        class="invalid-feedback"
        *ngIf="subject.invalid && subject.touched"
      >
        <span
          *ngIf="subject.getError('required')"
          i18n="
            contact form subject required error|Required error for the
            subject of contact form
          "
        >
          Veuillez renseigner le sujet de votre message.
        </span>
        <span
          *ngIf="subject.getError('maxlength')"
          i18n="
            contact form subject max length error|Max length error for the
            subject of contact form
          "
        >
          Le sujet ne doit pas dépasser 255 caractères.
        </span>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12">
      <label
        for="cbwsContactMessage"
        i18n="contact form message|Label for the message of contact form"
        title="Message"
      >
        Message <span class="required">*</span>
      </label>
      <textarea
        class="form-control"
        name="message"
        id="cbwsContactMessage"
        placeholder="Mon message"
        i18n-placeholder="
          contact form message placeholder|Placeholder for the message of
          contact form
        "
        rows="3"
        formControlName="message"
        title="Message"
      ></textarea>
      <div
        class="invalid-feedback"
        *ngIf="message.invalid && message.touched"
      >
        <span
          *ngIf="message.getError('required')"
          i18n="
            contact form message required error|Required error for the
            message of contact form
          "
        >
          Veuillez composer votre message.
        </span>
      </div>
    </div>
  </div>

  <div class="form-row" *ngIf="result != null">
    <div class="form-group col-12 text-center">
      <p
        class="text-accent-4"
        *ngIf="result.success; else error"
        i18n="
          contact form success message|Success feedback message after
          sending
        "
      >
        Votre message a été envoyé !
      </p>
      <ng-template #error>
        <div
          class="cbws-form-error-container text-danger"
          *ngIf="result.errors.length; else errorDefault"
        >
          <ul>
            <li *ngFor="let error of result.errors">{{ error }}</li>
          </ul>
        </div>
        <ng-template #errorDefault>
          <p
            class="text-accent-1"
            i18n="
              contact form default error message|Default error feedback
              message after sending
            "
          >
            Malheureusement votre message n'a pas pu être envoyé.
          </p>
        </ng-template>
      </ng-template>
    </div>
  </div>

  <div class="form-row">
    
    <div class="form-group col-12 text-center mb-0">
      <cbws-loading-spinner [diameter]="50" *ngIf="requesting"></cbws-loading-spinner>
      <button  *ngIf="!requesting"
        type="submit"
        class="btn btn-primary"
        i18n="
          contact form send button|Text inside the send button of the
          contact form
        "
        title="Envoyer votre message"
        i18n-title="
          contact form send button title|Title for the send button of the
          contact form
        "
      >
        Envoyer
      </button>
    </div>
  </div>
</form>


