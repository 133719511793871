import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Disposable } from 'src/app/components/core';
import { RoleType } from 'src/app/enums/role-type.enum';
import { AuthService } from 'src/app/services/auth.service';


export abstract class LoginFormViewBase<TFormData> extends Disposable {
  /**
   * Returns url to be used after login
   */
  protected returnUrl: string;

  /**
   * The login form
   */
  form: FormGroup;

  /**
   * Error response
   */
  error: HttpErrorResponse;

  /**
   * Flag indicating if the authentication is in progress
   */
  requesting: boolean;

  /**
   * LoginFormViewBase constructor
   */
  constructor(
    protected readonly route: ActivatedRoute,
    readonly title: Title,
    readonly pageTitle: string
  ) {
    super();
    title.setTitle(`${pageTitle} | Castle Bee`);
  }

  /**
   * Initializes the component
   */
  init(): void {
    this.buildForm();

    this.route.queryParamMap.subscribe(
      (p: ParamMap) => (this.returnUrl = p.get('returnUrl'))
    );
  }

  /**
   * Catchs and displays the HTTP error response
   * @param err HTTP error response
   */
  protected catchError(err: HttpErrorResponse): void {
    this.error = err;
  }

  /**
   * Completes component state after request
   */
  protected finalize(): void {
    this.requesting = false;
  }

  /**
   * Gets the form data
   */
  protected getFormData(): TFormData {
    return this.form.getRawValue() as TFormData;
  }

  /**
   * Redirects user to its home page or return url
   * @param authService application authentication service
   * @param router Angular's router
   */
  protected redirectToUserHomePage(
    authService: AuthService,
    router: Router
  ): void {
    if (this.returnUrl != null) {
      if (
        authService.role === RoleType.administrator &&
        !this.returnUrl.includes('administration')
      ) {
        router.navigate(['administration']);
      } else {
        router.navigateByUrl(this.returnUrl);
      }
    } else {
      if (authService.role === RoleType.administrator) {
        router.navigate(['administration']);
      } else {
        router.navigate(['home']);
      }
    }
  }

  /**
   * Resets component state before requesting
   */
  protected resetBeforeRequest(): void {
    this.error = null;
    this.requesting = true;
  }

  /**
   * Checks and submits the form
   */
  abstract onLogin(): void;

  /**
   * Creates and builds the form
   */
  protected abstract buildForm(): void;
}
