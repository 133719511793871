import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogConfirmData } from 'src/app/models/dialog-confirm-data';

@Component({
  selector: 'cbws-dialog-confirm',
  templateUrl: './dialog-confirm.component.html'
})
export class DialogConfirmComponent {
  /**
   * DialogConfirmComponent constructor
   * @param data dialog input data
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogConfirmData) {
    data.acceptText = data.acceptText || 'Yes';
    data.cancelText = data.cancelText || 'No';
  }
}
