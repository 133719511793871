import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ServiceBase } from './base.service';

@Injectable()
export abstract class JsonBaseService<T> extends ServiceBase {
  protected abstract jsonFileName: string;

  load(): Observable<Array<T>> {
    return this.httpClient.get<Array<T>>(this.getUrl());
  }

  loadSingle(): Observable<T> {
    return this.httpClient.get<T>(this.getUrl());
  }

  protected getUrl(): string {
    // return `${this.baseUrl}/assets/data/${this.jsonFileName}`;
    return `/assets/data/${this.jsonFileName}`;
  }
}
