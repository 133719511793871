import { Component } from '@angular/core';
import { IconButtonBase } from 'src/app/models/abstracts/icon-button-base';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'cbws-icon-delete-button',
  templateUrl: './icon-delete-button.component.html'
})
export class IconDeleteButtonComponent extends IconButtonBase {
  faTrash = faTrash;
}
