import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// TODO: Add Angular decorator.
@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class Disposable implements OnDestroy {
  protected sub$: Subject<void> = new Subject();

  ngOnDestroy(): void {
    this.sub$.next();
    this.sub$.complete();
  }
}