import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { faCode, faDatabase, faGlobeEurope, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

import { FormComponentBase } from 'src/app/components/core/form-component-base';
import { AdministrationJobOfferForm } from 'src/app/models/jobs/administration-job-offer-form';
import { findIconDefinition, IconDefinition, IconLookup } from '@fortawesome/fontawesome-svg-core';


// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
// tslint:disable-next-line:no-duplicate-imports
// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD MMMM YYYY',
  },
};

@Component({
  selector: 'cbws-administration-job-offer-form',
  templateUrl: './administration-job-offer-form.component.html',
  styleUrls: ['./administration-job-offer-form.component.scss']
})
export class AdministrationJobOfferFormComponent
  extends FormComponentBase<AdministrationJobOfferForm>
  implements OnInit {

  faPlus = faPlus;
  faTimes = faTimes;
  faDatabase = faDatabase;
  faGlobeEurope = faGlobeEurope;
  faCode = faCode;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  
  get expirationDate(): AbstractControl {
    return this.form.get('expirationDate');
  }
  get client(): AbstractControl {
    return this.form.get('client');
  }
  get title(): AbstractControl {
    return this.form.get('title');
  }
  get subtitle(): AbstractControl {
    return this.form.get('subtitle');
  }
  get grossAnnualSalary(): AbstractControl {
    return this.form.get('grossAnnualSalary');
  }
  get startDate(): AbstractControl {
    return this.form.get('startDate');
  }
  get summary(): AbstractControl {
    return this.form.get('summary');
  }
  get region(): AbstractControl {
    return this.form.get('region');
  }
  get city(): AbstractControl {
    return this.form.get('city');
  }
  get level(): AbstractControl {
    return this.form.get('level');
  }
  get competencies(): AbstractControl {
    return this.form.get('competencies');
  }
  get experience(): AbstractControl {
    return this.form.get('experience');
  }
  get icon(): AbstractControl {
    return this.form.get('icon');
  }
  get displayOrder(): AbstractControl {
    return this.form.get('displayOrder');
  }
  get contractType(): AbstractControl {
    return this.form.get('contractType');
  }
  get blocks(): FormArray {
    return this.form.get('blocks') as FormArray;
  }


  /**
   * AdministrationJobOfferFormComponent component
   * @param fb Angular's form builder
   */
  constructor(private readonly fb: FormBuilder) {
    super();
  }

  /**
   * Initializes the component
   */
  ngOnInit(): void {
    this.initialize();
  }

  /**
   * Creates and builds the form
   */
  protected buildForm(): void {
    this.form = this.fb.group({
      id: [null],
      expirationDate: [null],
      client: [null],
      title: [null],
      subtitle: [null],
      summary:[null],
      region: [null],
      city:[null],
      level: [null],
      experience:[0],
      competencies: [null],
      displayOrder: [0],
      contractType:['CDI'],
      startDate:[null],
      grossAnnualSalary:[null],
      icon:[faDatabase.iconName],
      blocks: this.fb.array(
        [this.buildBlockForm(), this.buildBlockForm()],
        [Validators.required, Validators.min(2)]
      )
    });
  }

  /**
   * Set the class icon value
   * @param value class icon value
   */
  setIcon(value:IconDefinition){
    this.icon.setValue({'iconName':value.iconName,'prefix':value.prefix});
    console.log(this.icon.value);
  }

  isIconSelected(value:IconDefinition){
    return this.icon.value?.iconName == value.iconName ? "#1E2D3B" : "#75B631";
  }

  /**
   * Adds a competency.
   * @param event chip input event
   */
   addCompetency(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();

    // Add our competency
    if (value != null && value !== '') {
      let values = this.competencies.value as Array<string>;
      if (values != null) {
        values.push(value);
      } else {
        values = [value];
      }

      this.competencies.setValue(values);
    }

    // Reset the input value
    if (input != null) {
      input.value = null;
    }
  }

  /**
   * Removes a competency.
   * @param competency competency to remove
   */
  removeCompetency(competency: string): void {
    const values = this.competencies.value as Array<string>;
    if (values == null || values.length < 1) {
      return;
    }

    const index = values.indexOf(competency);
    if (index >= 0) {
      values.splice(index, 1);
      this.competencies.setValue(values);
    }
  }

  /**
   * Adds a region.
   * @param event chip input event
   */
   addRegion(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();

    // Add our competency
    if (value != null && value !== '') {
      let values = this.region.value as Array<string>;
      if (values != null) {
        values.push(value);
      } else {
        values = [value];
      }

      this.region.setValue(values);
    }

    // Reset the input value
    if (input != null) {
      input.value = null;
    }
  }

  /**
   * Removes a region.
   * @param competency competency to remove
   */
  removeRegion(competency: string): void {
    const values = this.region.value as Array<string>;
    if (values == null || values.length < 1) {
      return;
    }

    const index = values.indexOf(competency);
    if (index >= 0) {
      values.splice(index, 1);
      this.region.setValue(values);
    }
  }

  /**
   * Adds a level.
   * @param event chip input event
   */
   addLevel(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();

    // Add our competency
    if (value != null && value !== '') {
      let values = this.level.value as Array<string>;
      if (values != null) {
        values.push(value);
      } else {
        values = [value];
      }

      this.level.setValue(values);
    }

    // Reset the input value
    if (input != null) {
      input.value = null;
    }
  }

  /**
   * Removes a level.
   * @param competency competency to remove
   */
  removeLevel(competency: string): void {
    const values = this.level.value as Array<string>;
    if (values == null || values.length < 1) {
      return;
    }

    const index = values.indexOf(competency);
    if (index >= 0) {
      values.splice(index, 1);
      this.level.setValue(values);
    }
  }

  /**
   * Adds a new nlock
   */
  addBlock(): void {
    this.blocks.push(this.buildBlockForm());
  }

  /**
   * Removes block at specified index
   * @param index index of the block
   */
   removeBlock(index: number): void {
    this.blocks.removeAt(index);
  }

  /**
   * Moves block to new index
   * @param currentIndex block current index
   * @param newIndex new index
   */
   moveBlock(currentIndex: number, newIndex: number): void {
    if (newIndex >= this.blocks.controls.length) {
      newIndex = this.blocks.controls.length - 1;
    }

    this.blocks.controls.splice(
      newIndex,
      0,
      this.blocks.controls.splice(currentIndex, 1)[0]
    );
  }

  /**
   * Creates and builds the block form
   */
   protected buildBlockForm(): FormGroup {
    return this.fb.group({
      title: [null, Validators.required],
      description: [null, Validators.required]
    });
  }

  /**
   * Sets the form data
   */
   protected setFormData(): void {
    if (this.formData == null) {
      return;
    }

    this.formData.blocks.forEach((_, i) => {
      if (i > 1) {
        this.blocks.push(this.buildBlockForm());
      }
    });

    this.form.patchValue(this.formData || {});
  }
}
