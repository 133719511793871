import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonComponentsModule } from './components/common/common-component.module';

import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { ApiCredentialsInterceptor } from './interceptors/api-credentials.interceptor';
import { ApiUrlInterceptor } from './interceptors/api-url.interceptor';

import { RootComponent } from './views/root/root.component';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { ProfileService } from './services';
import { AuthService } from './services/auth.service';
import { HomeViewComponent } from './views/home-view/home-view.component';
import { LoginFormCardComponent } from './components/login-form-card/login-form-card.component';
import { LoginAuthenticatorComponent } from './views/login-authenticator/login-authenticator.component';
import { LoginRecoveryCodeComponent } from './views/login-recovery-code/login-recovery-code.component';
import { LoginComponent } from './views/login/login.component';
import { RequestPasswordComponent } from './views/request-password/request-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { FooterComponent } from './sections/home-section/footer/footer.component';
import { HeaderComponent } from './sections/home-section/header/header.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { CopyrightComponent } from './sections/home-section/footer/copyright/copyright.component';
import { AdministrationViewComponent } from './views/administration-view/administration-view.component';
import { AdministrationHeaderComponent } from './sections/administration-section/header/administration-header.component';


@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    
    RecaptchaModule,
    CommonComponentsModule,
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: AppModule.init,
    //   deps: [SettingsService],
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiUrlInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiCredentialsInterceptor,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpBlobErrorInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    // { provide: LOCALE_ID, useValue: environment.localeId },

    // Guards
    AuthGuard,
    AdminGuard,

    // Services
    // SettingsService,
    AuthService,
    ProfileService,
    // AuthenticatorService
  ],
  declarations: [
    // Components
    LoginFormCardComponent,
    HeaderComponent,
    FooterComponent,
    CopyrightComponent,
    AdministrationHeaderComponent,

    // Views
    RootComponent,
    HomeViewComponent,
    LoginComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    LoginAuthenticatorComponent,
    LoginRecoveryCodeComponent,
    AdministrationViewComponent,
  ],
  bootstrap: [RootComponent],
  exports: []
})
export class AppModule {
  // constructor(faLibrary: FaIconLibrary) {
  //   faLibrary.addIconPacks(fab);
  // }

  // static init(settingsService: SettingsService) {
  //   return () => new Promise<void>((resolve, reject) => {
  //     settingsService
  //       .loadSettings()
  //       .toPromise()
  //       .catch(err => reject(err))
  //       .then(() => resolve());
  //   });
  // }
}
