import { HttpErrorResponse } from '@angular/common/http';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Disposable } from './disposable';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class FormComponentBase<TFormData> extends Disposable {
  /**
   * Form data to be set
   */
  @Input()
  formData: TFormData;
  /**
   * Error response
   */
  @Input()
  error: HttpErrorResponse;

  /**
   * Event when user cancels the form
   */
  @Output()
  cancelled: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Event that submits form data
   */
  @Output()
  submitted: EventEmitter<TFormData> = new EventEmitter<TFormData>();

  /**
   * Component form
   */
  form: FormGroup;

  /**
   * Cancels the form
   */
  cancel(): void {
    this.cancelled.emit();
  }

  /**
   * Submits the form
   */
  onSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    // Get form values
    const data = this.getFormData(this.form.getRawValue());

    // Submit
    this.submitted.emit(data);
  }

  /**
   * Manipulates form data before submitting
   * @param formData form data to manipulate
   */
  protected getFormData(formData: TFormData): TFormData {
    return formData;
  }

  /**
   * Initializes the component
   */
  protected initialize(): void {
    this.buildForm();
    this.setFormData();
  }

  /**
   * Sets the form data
   */
  protected setFormData(): void {
    this.form.patchValue(this.formData || {});
  }

  /**
   * Creates and builds the form
   */
  protected abstract buildForm(): void;
}
