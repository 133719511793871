import { Directive, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class IconButtonBase {
  /**
   * Title to be displayed on button
   */
  @Input()
  title: string;

  /**
   * Color to apply to the button
   */
  @Input()
  color: ThemePalette = 'accent';
}
