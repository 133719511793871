import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faBars, faPowerOff } from '@fortawesome/free-solid-svg-icons';

// import { NavbarLink } from '../../../models';

@Component({
  selector: 'cbws-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent{
  isExpanded = false;
  isSticky = true;
  faBars = faBars;
  faPowerOff = faPowerOff;

  constructor(
    private router: Router) {
  }

  toggle(): void {
    this.isExpanded = !this.isExpanded;
  }

  getFragment(url,fragment = ''): string {
    if (fragment === '')
    {
      return this.router.url === url ? 'active' : '' ;
    }
    else{
      return this.router.url === url +"#"+fragment ? 'active' : '' ;
    }
  }
}
