import { FileInput } from 'ngx-material-file-input';
import { DateHelper } from 'src/app/helpers';
import { ServiceBase } from './base.service';

export abstract class ApiBaseService extends ServiceBase {
  protected abstract controllerName: string;

  protected getUrl(action: string): string {
    return `api/${this.controllerName}/${action}`;
  }

  /**
   * Returns a new FormData populated with values of the provided obj.
   * @param obj object containing the values to be added in the FormData
   */
  protected createFormData(obj: any): FormData {
    const form = new FormData();

    // Iterate over object properties to map properties names as keys and their value
    Object.keys(obj).forEach((key: string) => {
      const value = obj[key];
      const strValue = this.objectValueToString(value);

      // Create key/value pair only if the value is defined
      if (value instanceof FileInput && value.files.length > 0) {
        form.append(key, value.files[0]);
      } else if (value instanceof FileList && value.length > 0) {
        form.append(key, value[0]);
      } else if (value instanceof File) {
        form.append(key, value);
      } else if (Array.isArray(value) && value.length > 0) {
        value.forEach((item: any) => {
          const v = this.objectValueToString(item);
          if (v != null) {
            form.append(`${key}[]`, v);
          }
        });
      } else if (strValue != null) {
        form.append(key, strValue);
      }
    });

    return form;
  }

  /**
   * Converts the provided value to string.
   * @param value value to convert to string
   */
  private objectValueToString(value: any): string {
    // Type that cannot be converted to string
    if (value == null ||
      typeof value === 'function' ||
      typeof value === 'symbol') {
      return null;
    }

    // Special case: Date
    if (typeof value === 'object' && DateHelper.isDate(value)) {
      return value.toISOString();
    } else if (Array.isArray(value) && value.length > 0) {
      if (this.objectValueToString(value[0]) != null) {
        return `[${value}]`; // value.toString();
      }
    } else if (typeof value === 'object') {
      return null;
    }

    // Other cases
    return value.toString();
  }
}
