import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { HomeArticleComponent } from './sections/home-section/home/articles/home-article.component';
import { AdministrationViewComponent } from './views/administration-view/administration-view.component';
import { HomeViewComponent } from './views/home-view/home-view.component';
import { LoginAuthenticatorComponent } from './views/login-authenticator/login-authenticator.component';
import { LoginRecoveryCodeComponent } from './views/login-recovery-code/login-recovery-code.component';
import { LoginComponent } from './views/login/login.component';
import { RequestPasswordComponent } from './views/request-password/request-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'login-two-factor',
    component: LoginAuthenticatorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login-recovery-code',
    component: LoginRecoveryCodeComponent,
    canActivate: [AuthGuard]
  },
  { path: 'request-password', component: RequestPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: 'home',
    component: HomeViewComponent,
    loadChildren: () => import('./sections/home-section/home-components.module').then(m => m.HomeComponentsModule)
  },
  {
    path: 'administration',
    component: AdministrationViewComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./sections/administration-section/administration-components.module').then(m => m.AdministrationComponentsModule)
  },


  // {
  //   path: 'security',
  //   component: AdministrationViewComponent,
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./security/security.module').then(m => m.SecurityModule)
  // },
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', relativeLinkResolution: 'legacy',scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
