import { Component } from '@angular/core';

/**
 * Component used to create a row form group.
 */
@Component({
  selector: 'cbws-form-group',
  templateUrl: './form-group.component.html'
})
export class FormGroupComponent { }
