import { Component, OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { filter, takeUntil } from 'rxjs/operators';

import { Constants, Social } from '../../../models';
import { ConstantsService } from '../../../services';
import { Disposable } from '../../../components/core';

@Component({
  selector: 'cbws-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends Disposable implements OnInit {
  socials: Array<Social>;
  constants: Constants;

  constructor(
    private readonly constantsService: ConstantsService,
    private readonly iconLibrary: FaIconLibrary) {
    super();

    this.socials = [];
  }

  ngOnInit(): void {
    this.constantsService.constants$
      .pipe(
        filter(c => c != null),
        takeUntil(this.sub$)
      )
      .subscribe(c => {
        this.constants = c;
        this.loadSocialNetworks();
      });
  }

  private getIconByName(name: IconName): IconDefinition {
    return this.iconLibrary.getIconDefinition('fab', name);
  }

  private loadSocialNetworks(): void {
    if (this.constants == null) {
      throw new Error('You must call this function after the constants has been loaded');
    }

    this.socials = this.constants.socialNetworks.map(
      n => ({
        title: n?.name,
        icon: this.getIconByName(n.icon),
        url: n.link
      })
    );
  }
}
