import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faBars, faSignOutAlt, faUserEdit, faTools } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';

import { NavbarLink } from '../../../models';

@Component({
  selector: 'cbws-administration-header',
  templateUrl: './administration-header.component.html',
  styleUrls: ['./administration-header.component.scss']
})
export class AdministrationHeaderComponent{
  isExpanded = false;
  isSticky = true;
  links: Array<NavbarLink>;
  faBars = faBars;
  faSignOutAlt = faSignOutAlt;
  faUserEdit = faUserEdit;
  faTools = faTools;

  constructor(
    public readonly authService: AuthService,
    private router: Router) {
    this.links = [
      
    ];
  }

  collapse(): void {
    this.isExpanded = false;
  }

  toggle(): void {
    this.isExpanded = !this.isExpanded;
  }

  getFragment(url,fragment = ''): string {
    if (fragment === '')
    {
      return this.router.url === url ? 'active' : '' ;
    }
    else{
      return this.router.url === url +"#"+fragment ? 'active' : '' ;
    }
  }

  /**
   * Logs the current user out
   */
   logout(): void {
    this.authService.logout();
    this.router.navigate(['/home']);
  }
}
