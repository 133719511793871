import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { LoginFormViewBase, UserTwoFactorAuth } from 'src/app/models';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'cbws-login-authenticator',
  templateUrl: './login-authenticator.component.html'
})
export class LoginAuthenticatorComponent
  extends LoginFormViewBase<UserTwoFactorAuth>
  implements OnInit, OnDestroy {
  /**
   * Gets the username form control
   */
  get code(): AbstractControl {
    return this.form.get('code');
  }

  /**
   * LoginAuthenticatorComponent constructor
   * @param authService authentication service
   * @param fb Angular's form builder
   * @param route Angular's current route
   * @param router Angular's router service
   * @param title Angular's title service
   */
  constructor(
    private readonly authService: AuthService,
    private readonly fb: FormBuilder,
    readonly route: ActivatedRoute,
    private readonly router: Router,
    readonly title: Title
  ) {
    super(route, title, 'Two-factor Authentication');
  }

  /**
   * Initializes the component
   */
  ngOnInit(): void {
    this.init();
  }

  /**
   * Completes all subscriptions
   */
  ngOnDestroy(): void {
    this.ngOnDestroy();
  }

  /**
   * Cancels authentication
   */
  onCancel(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  /**
   * Checks and submits the two factor authenticator code
   */
  onLogin(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    }

    this.resetBeforeRequest();
    const credentials = this.getFormData();

    this.authService
      .loginTwoFactor(credentials)
      .pipe(finalize(() => this.finalize()))
      .subscribe(
        () => this.redirectToUserHomePage(this.authService, this.router),
        (err: HttpErrorResponse) => this.catchError(err)
      );
  }

  /**
   * Creates the login forms
   */
  protected buildForm(): void {
    this.form = this.fb.group({
      code: [null, Validators.required]
    });
  }
}
