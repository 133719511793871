import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'cbws-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxFilterComponent),
      multi: true
    }
  ]
})
export class CheckboxFilterComponent implements ControlValueAccessor, OnInit {
  /**
   * Color to apply to the button
   */
  @Input()
  color: ThemePalette = 'accent';

  /**
   * Inner control used for text display
   */
  innerCtrl: FormControl = new FormControl();

  /**
   * Initializes the component
   */
  ngOnInit(): void {
    this.innerCtrl.valueChanges.subscribe((value: boolean) =>
      this.onChange(value)
    );
  }

  /**
   * Function used to notify parent that the value has changed
   */
  onChange: (value: boolean) => void = (_: boolean) => { };
  /**
   * Function used to notify parent that the control has been touched
   */
  onTouched: () => void = () => { };

  /**
   * Registers the function to be used to notify parent on value change
   * @param fn the function to be used to notify parent on value change
   */
  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  /**
   * Registers the function to be used to notify parent on control touched
   * @param fn the function to be used to notify parent on control touched
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Writes the value into this control
   * @param value the external value to be set
   */
  writeValue(value: boolean): void {
    this.onChange(value);
    this.innerCtrl.patchValue(value);
  }
}
