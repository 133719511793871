<cbws-administration-header></cbws-administration-header>
<!-- <cbws-header></cbws-header> -->

<div style="padding-top:150px;">
  <div class="">
    <mat-drawer-container autosize >
    
    <mat-drawer #largeMenu mode="over" position="start">
      <div class="small-navigation">
        <button type="button" mat-button (click)="close();" style="width: 100%;">
          <fa-icon [icon]="faAngleDoubleLeft"></fa-icon>
        </button>
      </div>
      
      <hr />
      
      <div class="nav-item large-navigation">
        
        <a class="nav-link p-2" title="Offres" [routerLink]="['/administration/offres']" routerLinkActive="active" >
              <fa-icon [icon]="faFileSignature"></fa-icon>
              Offres
            </a>
            
        <a class="nav-link p-2" title="Entreprises" [routerLink]="['/administration/companies']" routerLinkActive="active" >
          <fa-icon [icon]="faBuilding"></fa-icon>
          Entreprises
        </a>
        
        <a class="nav-link p-2" title="Articles" [routerLink]="['/administration/articles']" routerLinkActive="active" >
          <fa-icon [icon]="faNewspaper"></fa-icon>
          Articles
        </a>

        <a class="nav-link p-2" title="Meetings" [routerLink]="['/administration/meeting']" routerLinkActive="active" >
          <fa-icon [icon]="faHandshake"></fa-icon>
          Meetings
        </a>

        <a class="nav-link p-2" title="Priorités" [routerLink]="['/administration/priorities']" routerLinkActive="active" >
          <fa-icon [icon]="faEdit"></fa-icon>
          Priorités
        </a>

        <a class="nav-link p-2" title="Types de meeting" [routerLink]="['/administration/meetingtype']" routerLinkActive="active" >
          <fa-icon [icon]="faEdit"></fa-icon>
          Types de meeting
        </a>
      </div>
      </mat-drawer>

    <div fxLayout="row wrap">
      <div fxFlex="5%" >
        <mat-card>
          <button type="button" mat-button (click)="open();">
            <fa-icon [icon]="faBars"></fa-icon>
          </button>
          
          <hr/>
          
          <div class="nav-item small-navigation">
            
            <a color="primary" class="nav-link p-2" title="Offres" [routerLink]="['/administration/offres']" routerLinkActive="active" >
              <fa-icon [icon]="faFileSignature"></fa-icon>
            </a>
            
            <a class="nav-link p-2" title="Entreprises" [routerLink]="['/administration/companies']" routerLinkActive="active" >
              <fa-icon [icon]="faBuilding"></fa-icon>
            </a>
            
            <a class="nav-link p-2" title="Articles" [routerLink]="['/administration/articles']" routerLinkActive="active" >
              <fa-icon [icon]="faNewspaper"></fa-icon>
            </a>
            
            <a class="nav-link p-2" title="Meetings" [routerLink]="['/administration/meeting']" routerLinkActive="active" >
              <fa-icon [icon]="faHandshake"></fa-icon>
            </a>
            
            <a class="nav-link p-2" title="Priorités" [routerLink]="['/administration/priorities']" routerLinkActive="active" >
              <fa-icon [icon]="faEdit"></fa-icon>
            </a>
            
            <a class="nav-link p-2" title="Types de meeting" [routerLink]="['/administration/meetingtype']" routerLinkActive="active" >
              <fa-icon [icon]="faEdit"></fa-icon>
            </a>
        </div>
        </mat-card>
      </div>
      <div fxFlex="95%">
        <router-outlet></router-outlet>
      </div>
    </div>

  </mat-drawer-container>

  </div>

</div>
