<cbws-card-color color="accent" class="fsm-text-accent" *ngIf="hasSuccess">
  <div fxLayout="column">
    <ng-container *ngIf="messages && messages.length; else singleSuccess">
      <div *ngFor="let msg of messages">
        {{ msg }}
      </div>
    </ng-container>
    <ng-template #singleSuccess>
      {{ message }}
    </ng-template>
  </div>
</cbws-card-color>
