import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'cbws-input-error',
  templateUrl: './input-error.component.html'
})
export class InputErrorComponent {
  /**
   * The control that must have its errors handled
   */
  @Input()
  control: AbstractControl;

  /**
   * The label that will be used in error strings
   */
  @Input()
  label: string;
}
