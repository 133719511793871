<!-- Title -->
<h1 mat-dialog-title>{{ data.title }}</h1>

<!-- Content -->
<mat-dialog-content>
  <p>{{ data.content }}</p>
</mat-dialog-content>

<!-- Actions -->
<mat-dialog-actions fxLayoutAlign="space-around">
  <button mat-button color="warn" [mat-dialog-close]="false">
    {{ data.cancelText }}
  </button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true">
    {{ data.acceptText }}
  </button>
</mat-dialog-actions>
