import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ErrorContent } from '../models';

/**
 * Service that handles errors.
 */
@Injectable({ providedIn: 'root' })
export class ErrorService {
  /**
   * Handles and returns the errors from the HttpError.
   * @param httpError http error to handle
   */
  handleHttpError(httpError: any): Array<ErrorContent> {
    // Handle error uset
    if (httpError == null) {
      return [];
    }

    let errors = [];

    // Handle HTTP response error
    if (httpError instanceof HttpErrorResponse) {
      if (httpError.error instanceof ErrorEvent) {
        errors.push({ code: 'ERROR', description: httpError.error.message });
        return errors; // Nothing else to do
      } else if (httpError.error instanceof ProgressEvent) {
        errors.push({ code: 'ERROR', description: httpError.message });
        return errors; // Nothing else to do
      } else if (typeof httpError.error  == 'string') {
        errors.push({ code: 'ERROR', description: httpError.error });
        return errors;
      }
      else {
        return [...errors, ...this.handleHttpError(httpError.error)];
      }
    }

    if (Array.isArray(httpError.errors)) {
      errors = [...errors, ...httpError.errors];
    }

    // Handle other error
    // if (Array.isArray(httpError)) {
    //   errors = [...errors, ...httpError];
    // } else {
    //   errors.push(httpError);
    // }

    return errors;
  }
}
