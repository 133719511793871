import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

/**
 * Component used to display some success messages.
 */
@Component({
  selector: 'cbws-http-success',
  templateUrl: './http-success.component.html'
})
export class HttpSuccessComponent implements OnChanges {
  /**
   * Success message received.
   */
  @Input()
  httpSuccess: any;

  /**
   * Single message to be displayed.
   */
  message: string;
  /**
   * Multiple messages to be displayed.
   */
  messages: Array<string>;

  /**
   * Gets if the component must display messages.
   */
  get hasSuccess(): boolean {
    return this.message != null || (this.messages != null && this.messages.length > 0);
  }

  /**
   * Listens for the input changes.
   * @param changes input changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes != null && changes.httpSuccess != null
      && changes.httpSuccess.currentValue !== changes.httpSuccess.previousValue) {
      this.handleHttpSuccess(changes.httpSuccess.currentValue);
    }
  }

  /**
   * Handles and format success messages to be displayed.
   * @param httpSuccess success that occured
   */
  private handleHttpSuccess(httpSuccess: any): void {
    // Handle success unset
    if (httpSuccess == null) {
      this.message = null;
      this.messages = null;
      return;
    }

    // Handle other success
    if (Array.isArray(httpSuccess)) {
      this.message = null;
      this.messages = httpSuccess;
    } else {
      this.message = httpSuccess;
      this.messages = null;
    }
  }
}
