import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { CanColor, mixinColor, ThemePalette } from '@angular/material/core';

/**
 * Base class
 */
class CardColorBase {
  // tslint:disable-next-line: variable-name
  constructor(public _elementRef: ElementRef) { }
}
const CardColorMixinBase: typeof CardColorBase = mixinColor(CardColorBase, 'primary');

/**
 * Component that creates an AngularMaterial MatCard component with theme background color.
 */
@Component({
  selector: 'cbws-card-color',
  templateUrl: './card-color.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CardColorComponent extends CardColorMixinBase implements CanColor {
  /**
   * CSS class name set on host component <fsm-card-color>.
   */
  @HostBinding('class')
  readonly class: string = 'cbws-card-color';

  defaultColor:ThemePalette;

  /**
   * Input theme color.
   */
  @Input()
  color: ThemePalette;

  /**
   * CardColorComponent constructor.
   * @param elementRef component HTML element
   */
  constructor(elementRef: ElementRef) {
    super(elementRef);
  }
}
