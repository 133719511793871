import { Component, Input } from '@angular/core';
import { IconButtonBase } from 'src/app/models/abstracts/icon-button-base';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'cbws-image-title',
  templateUrl: './image-title.component.html',
  styleUrls: ['./image-title.component.scss']
})
export class ImageTitleComponent  {
    @Input()
    title: string;

    @Input()
    text: string;

    @Input()
    url: string;
}
