/**
 * Helper for the LocalStorage.
 */
export class LocalStorageHelper {
  /**
   * Checks if the current user browser has a local storage.
   */
  static hasLocalStorage(): boolean {
    return typeof localStorage !== 'undefined';
  }
}
