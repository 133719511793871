<cbws-login-form-card [requesting]="requesting">
  <form [formGroup]="form" (ngSubmit)="onLogin()">
    <cbws-form-card-content>
      <!-- Username -->
      <mat-form-field fxFlex="100%">
        <mat-label>Identifiant</mat-label>
        <input
          type="text"
          matInput
          formControlName="username"
          placeholder="Enter username"
        />
        <mat-error *ngIf="username.invalid">
          <cbws-input-error
            [control]="username"
            label="Username"
          ></cbws-input-error>
        </mat-error>
      </mat-form-field>

      <!-- Password -->
      <mat-form-field fxFlex="100%">
        <mat-label>Mot de passe</mat-label>
        <input
          type="password"
          matInput
          formControlName="password"
          placeholder="Enter password"
        />
        <mat-error *ngIf="password.invalid">
          <cbws-input-error
            [control]="password"
            label="Password"
          ></cbws-input-error>
        </mat-error>
      </mat-form-field>

      <!-- Errors -->
      <cbws-api-errors [error]="error" fxFlex="100%"></cbws-api-errors>

      <!-- Actions -->
      <cbws-form-card-action fxFlex="50%">
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="requesting"
        >
          Connexion
        </button>
      </cbws-form-card-action>
      <div fxFlex="50%" fxLayout="row-reverse" style="margin:auto;">
        <a [routerLink]="['/request-password']">
          Mot de passe oublié?
        </a>
      </div>
    </cbws-form-card-content>
  </form>
</cbws-login-form-card>

