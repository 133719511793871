import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {
  /**
   * Previous url stored
   */
  previousRoute: string;
  /**
   * Current url
   */
  currentRoute: string;

  /**
   * Checks if user can go back
   */
  get canBack(): boolean {
    return (
      this.previousRoute !== '/' && this.previousRoute !== this.currentRoute
    );
  }

  /**
   * Previous route service
   * @param router Angular's router
   */
  constructor(private readonly router: Router) {
    this.currentRoute = this.router.url;

    router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.previousRoute = this.currentRoute;
        this.currentRoute = e.url;
      });
  }
}
