import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserPasswordForm, UserProfileForm } from '../models';


@Injectable()
export class ProfileService {
  /**
   * ProfileService constructor
   * @param http Angular's HTTP service
   */
  constructor(private readonly http: HttpClient) {}

  /**
   * Gets current user's profile
   */
  getUserProfileForm(): Observable<UserProfileForm> {
    return this.http.get<UserProfileForm>(`api/user/profile`);
  }

  /**
   * Changes user password on server
   * @param formData user password form data
   */
  changePassword(formData: UserPasswordForm): Observable<void> {
    return this.http.post<void>('api/user/change-password', formData);
  }

  /**
   * Submits and updates current user profile
   * @param formData user form data
   */
  updateProfile(formData: UserProfileForm): Observable<void> {
    return this.http.put<void>('api/user/update-profile', formData);
  }
}
