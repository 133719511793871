import { Injectable } from '@angular/core';

import { Client } from '../models';
import { JsonBaseService } from './abstracts/json-base.service';

@Injectable({ providedIn: 'root' })
export class ClientService extends JsonBaseService<Client> {
  protected jsonFileName = 'clients.json';
}


