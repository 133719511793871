import { Directive } from '@angular/core';

import { environment } from '../../../environments/environment';
import { DisposableObservableDirective } from './disposable-observable.directive';

/**
 * Abstract class for the component that uses requests and error states.
 */
@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class RequestingBaseComponent extends DisposableObservableDirective {
  /**
   * Http error state.
   */
  httpError: any;
  /**
   * Http success state.
   */
  httpSuccess: any;
  /**
   * Flag indicating if the form is loading or submitted.
   */
  isRequesting: boolean;

  /**
   * RequestingBaseComponent constructor.
   */
  constructor() {
    super();

    this.setError.bind(this);
    this.isRequesting = true;
  }

  /**
   * Sets an error.
   * @param httpError error to set
   */
  protected setError(httpError: any): void {
    if (httpError && !environment.production) {
      console.error(httpError);
    }

    this.httpError = httpError;
  }

  /**
   * Sets the requesting state.
   * @param requesting the value of the requesting state to set
   */
  protected setRequestingState(requesting: boolean): void {
    this.isRequesting = requesting;
  }

  /**
   * Sets a success.
   * @param httpSuccess success to set
   */
  protected setSuccess(httpSuccess: any): void {
    this.httpSuccess = httpSuccess;
  }
}
