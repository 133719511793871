import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconButtonBase } from 'src/app/models/abstracts/icon-button-base';

@Component({
  selector: 'cbws-icon-button',
  templateUrl: './icon-button.component.html'
})
export class IconButtonComponent extends IconButtonBase{
  
  /**
   * Icon to be set in button
   */
  @Input()
  icon: IconProp;
}
