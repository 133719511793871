import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cbws-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent {
  year: number;

  constructor() {
    this.year = new Date().getFullYear();
  }
}
