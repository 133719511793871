import { Component } from '@angular/core';
import { IconButtonBase } from 'src/app/models/abstracts/icon-button-base';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'cbws-icon-up-button',
  templateUrl: './icon-up-button.component.html'
})
export class IconUpButtonComponent extends IconButtonBase {
  faArrowUp = faArrowUp;
}
