<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <cbws-form-card-content>

    <div fxFlex="100%" fxLayout="row wrap" fxLayoutGap="16px">
      <!-- Client field -->
      <mat-form-field fxFlex>
        <mat-label>Client</mat-label>
        <input type="text" formControlName="client" matInput placeholder="Client" />
        <mat-error *ngIf="client.invalid">
          <cbws-input-error [control]="client" label="Client"></cbws-input-error>
        </mat-error>
      </mat-form-field>

      <!-- City  field -->
      <mat-form-field fxFlex>
        <mat-label>Ville</mat-label>
        <input type="text" formControlName="city" matInput placeholder="Ville" />
        <mat-error *ngIf="city.invalid">
          <cbws-input-error [control]="city" label="Villelient"></cbws-input-error>
        </mat-error>
      </mat-form-field>

    </div>
    <div fxFlex="100%" fxLayout="row wrap" fxLayoutGap="16px">

      <!-- Region field -->
      <mat-form-field fxFlex>
        <mat-chip-list #chipListRegion>
          <mat-chip
            *ngFor="let rg of region.value"
            [selectable]="true"
            [removable]="true"
            (removed)="removeRegion(rg)"
          >
            {{ rg }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Région"
            [matChipInputFor]="chipListRegion"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addRegion($event)"
          />
        </mat-chip-list>
        <mat-error *ngIf="region.touched && region.invalid">
          <cbws-input-error [control]="region" label="Région"></cbws-input-error>
        </mat-error>
      </mat-form-field>
    </div>
    
    <div fxFlex="100%" fxLayout="row wrap" fxLayoutGap="16px">
      <!-- Title field -->
      <mat-form-field fxFlex>
        <mat-label>Titre</mat-label>
        <input type="text" formControlName="title" matInput placeholder="Titre" />
        <mat-error *ngIf="title.invalid">
          <cbws-input-error [control]="title" label="Titre"></cbws-input-error>
        </mat-error>
      </mat-form-field>
      
      <!-- Subtitle field -->
      <mat-form-field fxFlex>
        <mat-label>Sous-titre</mat-label>
        <input type="text" formControlName="subtitle" matInput placeholder="Sous-titre" />
        <mat-error *ngIf="subtitle.invalid">
          <cbws-input-error [control]="subtitle" label="Sous-titre"></cbws-input-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100%" fxLayout="row wrap" fxLayoutGap="16px">
      <!-- Title field -->
      <mat-form-field fxFlex>
        <mat-label>Résumé</mat-label>
        <textarea  formControlName="summary" matInput placeholder="Résumé" rows="3"></textarea>
        <mat-error *ngIf="summary.invalid">
          <cbws-input-error [control]="summary" label="Résumé"></cbws-input-error>
        </mat-error>
      </mat-form-field>
    </div>
    
    <div fxFlex="100%" fxLayout="row wrap" fxLayoutGap="16px">
      <!-- Level field -->
      <mat-form-field fxFlex>
        <mat-chip-list #chipListLevel>
          <mat-chip
            *ngFor="let lvl of level.value"
            [selectable]="true"
            [removable]="true"
            (removed)="removeLevel(lvl)"
          >
            {{ lvl }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Level"
            [matChipInputFor]="chipListLevel"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addLevel($event)"
          />
        </mat-chip-list>
        <mat-error *ngIf="competencies.touched && competencies.invalid">
          <cbws-input-error [control]="competencies" label="Compétences"></cbws-input-error>
        </mat-error>
      </mat-form-field>

      <!-- Competencies -->
      <mat-form-field fxFlex>
        <mat-chip-list #chipListCompetencies>
          <mat-chip
            *ngFor="let competency of competencies.value"
            [selectable]="true"
            [removable]="true"
            (removed)="removeCompetency(competency)"
          >
            {{ competency }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Compétences"
            [matChipInputFor]="chipListCompetencies"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addCompetency($event)"
          />
        </mat-chip-list>
        <mat-error *ngIf="competencies.touched && competencies.invalid">
          <cbws-input-error [control]="competencies" label="Compétences"></cbws-input-error>
        </mat-error>
      </mat-form-field>
    </div>
    
    <div fxFlex="100%" fxLayout="row wrap" fxLayoutGap="16px">
      <!-- Salary -->
      <mat-form-field fxFlex>
        <mat-label>Salaire annuel brut</mat-label>
        <input
          type="number"
          formControlName="grossAnnualSalary"
          matInput
          placeholder="Salaire annuel brut"
          min="0"
          step="5000"
        />
        <mat-error *ngIf="grossAnnualSalary.invalid">
          <cbws-input-error [control]="grossAnnualSalary" label="Salaire annuel brut"></cbws-input-error>
        </mat-error>
      </mat-form-field>

      <!-- Eperience -->
      <mat-form-field fxFlex>
        <mat-label>Experience</mat-label>
        <input
          type="number"
          formControlName="experience"
          matInput
          placeholder="Eperience"
          min="0"
          step="1"
        />
        <mat-error *ngIf="experience.invalid">
          <cbws-input-error [control]="experience" label="Experience"></cbws-input-error>
        </mat-error>
      </mat-form-field>

      <!-- Display order -->
      <mat-form-field fxFlex>
        <mat-label>Ordre d'affichage</mat-label>
        <input
          type="number"
          formControlName="displayOrder"
          matInput
          placeholder="Ordre d'affichage"
          min="0"
          step="1"
        />
        <mat-error *ngIf="displayOrder.invalid">
          <cbws-input-error [control]="displayOrder" label="Ordre d'affichage"></cbws-input-error>
        </mat-error>
      </mat-form-field>

      <!-- Start Date -->
      <mat-form-field fxFlex>
        <mat-label>Date de commencement</mat-label>
        <input
          matInput
          formControlName="startDate"
          [matDatepicker]="startDatePicker"
          placeholder="Date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #startDatePicker
        ></mat-datepicker>
      </mat-form-field>

      <!-- Expiration Date -->
      <mat-form-field fxFlex>
        <mat-label>Date d'expiration</mat-label>
        <input
          matInput
          formControlName="expirationDate"
          [matDatepicker]="expirationDatePicker"
          placeholder="Date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="expirationDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #expirationDatePicker
          
        ></mat-datepicker>
      </mat-form-field>
    </div>

    <div fxFlex="100%" fxLayout="row wrap" fxLayoutGap="16px">
        <mat-label>Type de contrat</mat-label>
        <mat-radio-group aria-labelledby="Contrat" formControlName="contractType">
            <mat-radio-button style="margin-left:7px ;" value="CDI">CDI</mat-radio-button>
            <mat-radio-button value="CDD">CDD</mat-radio-button>
          </mat-radio-group>
    </div>

    <div fxFlex="100%" fxLayout="row wrap" fxLayoutGap="16px">
      <mat-label>Icone</mat-label>
      <button type="button" mat-icon-button (click)="setIcon(faDatabase)">
        <fa-icon [icon]="faDatabase" class="icon-2x" [style.color]="isIconSelected(faDatabase)"></fa-icon>
      </button>
      <button type="button" mat-icon-button (click)="setIcon(faGlobeEurope)">
        <fa-icon [icon]="faGlobeEurope" class="icon-2x" [style.color]="isIconSelected(faGlobeEurope)"></fa-icon>
      </button>
      <button type="button" mat-icon-button (click)="setIcon(faCode)">
        <fa-icon [icon]="faCode" class="icon-2x" [style.color]="isIconSelected(faCode)"></fa-icon>
      </button>
      
      
    </div>

    <!-- Blocks -->
    <mat-divider class="mt-3 mb-3" fxFlex="100%"></mat-divider>
    <h2 class="mat-h2 text-black-50" fxFlex="100%">Blocs</h2>

    <button class="mb-3" type="button" mat-stroked-button (click)="addBlock()">
      <fa-icon [icon]="faPlus"></fa-icon>
      Ajouter un bloc
    </button>

    <div class="pl-4" formArrayName="blocks" fxFlex="100%" fxLayout="column">
      <div
        *ngFor="let block of blocks.controls; let index = index"
        [formGroup]="block"
        fxFlex="100%"
        fxLayout="column"
      >
        <div fxFlex="100%">
          <h3 class="mat-h3 text-black-50" fxFlex="50%">
            Bloc {{ index + 1 }}
          </h3>
          <div fxFlex="50%" fxLayout="row" fxLayoutAlign="end">
            <div [style.margin-right]="index < 2 ? '40px' : '0'">
              <cbws-icon-up-button
                *ngIf="index > 0"
                [style.margin-right]="
                  index === blocks.controls.length - 1 ? '40px' : '0'
                "
                [title]="'Move block ' + (index + 1) + ' up'"
                (click)="moveBlock(index, index - 1)"
              ></cbws-icon-up-button>
              <cbws-icon-down-button
                *ngIf="index < blocks.controls.length - 1"
                [title]="'Move block ' + (index + 1) + ' down'"
                (click)="moveBlock(index, index + 1)"
              ></cbws-icon-down-button>
            </div>
            <button
              *ngIf="index > 1"
              type="button"
              mat-icon-button
              [title]="'Remove block ' + (index + 1)"
              (click)="removeBlock(index)"
            >
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
          </div>
        </div>

        <div fxFlex="100%" fxLayout="row wrap" fxLayoutGap="16px">
          <!-- Block title -->
          <mat-form-field fxFlex="20%" fxFlex.lt-md="100%">
            <mat-label>Titre</mat-label>
            <input formControlName="title" matInput placeholder="Titre du bloc" />
            <mat-error *ngIf="block.get('title').invalid">
              <cbws-input-error [control]="block.get('title')" label="Titre"></cbws-input-error>
            </mat-error>
          </mat-form-field>

          <!-- Block Description -->
          <mat-form-field fxFlex fxFlex.lt-md="100%">
            <mat-label>Description</mat-label>
            <textarea
              formControlName="description"
              matInput
              placeholder="Description"
              rows="1"
            ></textarea>
            <mat-error *ngIf="block.get('description').invalid">
              <cbws-input-error [control]="block.get('description')" label="Description"></cbws-input-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- Errors -->
    <cbws-api-errors [error]="error" fxFlex="100%"></cbws-api-errors>

    <!-- Actions -->
    <cbws-form-card-action fxFlex="100%">
      <button type="button" class="btn btn-light" (click)="cancel()" fxFlex>
        Cancel
      </button>
      <button type="submit" class="btn btn-primary" fxFlex>
        Save
      </button>
    </cbws-form-card-action>
  </cbws-form-card-content>
</form>
