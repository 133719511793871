import { Component, Directive, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Address, Constants, WebSite } from 'src/app/models';
import { ConstantsService } from 'src/app/services';
import { Disposable } from '../components/core';


@Directive()
export abstract class GetConstants extends Disposable implements OnInit {
  public constants: Constants;
  public siege: Address;
  public site: WebSite;
  
  constructor(protected readonly constantsService: ConstantsService) {
    super();
  }

  ngOnInit(): void {
    this.constantsService.constants$
      .pipe(
        filter(c => c != null),
        takeUntil(this.sub$)
      )
      .subscribe(c => {
        this.constants = c;
        this.siege = this.getSiegeAddress();
        this.site = this.getSite();
      });
  }

  private getSiegeAddress(): Address {
    if (this.constants == null) {
      throw new Error('You must call this function after the constants loading');
    }

    return this.constants.addresses?.find(a => a.name === 'Siège');
  }

  private getSite(index = 0): WebSite {
    if (this.constants == null) {
      throw new Error('You must call this function after the constants loading');
    }

    return this.constants.sites[index];
  }
}