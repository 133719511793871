import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ApiCredentialsInterceptor implements HttpInterceptor {
  /**
   * Intercepts every request to attach the user credentials
   * @param request the current request
   * @param next the request handler
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request.clone({ withCredentials: true }));
  }
}
