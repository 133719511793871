import { Component } from '@angular/core';
import { IconButtonBase } from 'src/app/models/abstracts/icon-button-base';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'cbws-icon-down-button',
  templateUrl: './icon-down-button.component.html'
})
export class IconDownButtonComponent extends IconButtonBase {
  faArrowDown = faArrowDown;
}
