import { Component,ViewChild,ElementRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { faBars, faSignOutAlt, faUserEdit, faTools,faFileSignature,faBuilding,
  faEdit,
  faAngleDoubleLeft,
  faNewspaper,
  faHandshake} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'cbws-administration-view',
  templateUrl: './administration-view.component.html',
  styleUrls: ['./administration-view.component.scss']
})
export class AdministrationViewComponent {
  @ViewChild('largeMenu', { static: false }) largeMenu: MatSidenav;
  //@ViewChild('smallMenu', { static: false }) smallMenu: MatSidenav;

  open():void{
    //this.smallMenu.close();
    this.largeMenu.open();

  }
  close():void{
    //this.smallMenu.open();
    this.largeMenu.close();

  }

  faBars = faBars;
  faSignOutAlt = faSignOutAlt;
  faUserEdit = faUserEdit;
  faTools = faTools;
  faFileSignature=faFileSignature;
  faBuilding=faBuilding;
  faHandshake=faHandshake;
  faEdit=faEdit;
  faAngleDoubleLeft=faAngleDoubleLeft;
  faNewspaper=faNewspaper;
}