import { Component, Input } from '@angular/core';

@Component({
  selector: 'cbws-login-form-card',
  templateUrl: './login-form-card.component.html'
})
export class LoginFormCardComponent {
  /**
   * Flag indicating a request is in progress
   */
  @Input()
  requesting: boolean;
}
