import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterBase } from 'src/app/models/abstracts/filter-base';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'cbws-simple-filter',
  templateUrl: './simple-filter.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleFilterComponent),
      multi: true
    }
  ]
})
export class SimpleFilterComponent extends FilterBase<string> {
  faTimes = faTimes;
}
