import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RequestPasswordForm } from 'src/app/models';
import { AuthService } from 'src/app/services/auth.service';



@Component({
  selector: 'cbws-request-password',
  templateUrl: './request-password.component.html'
})
export class RequestPasswordComponent implements OnInit {
  /**
   * The login form
   */
  form: FormGroup;
  /**
   * HTTP error response
   */
  error: HttpErrorResponse;
  /**
   * Success message
   */
  success: string;

  /**
   * Gets the email form control
   */
  get email(): AbstractControl {
    return this.form.get('email');
  }

  /**
   * RequestPasswordComponent constructor
   * @param authService Authentication service
   * @param fb Angular's form builder
   * @param title Angular's title service
   */
  constructor(
    private readonly authService: AuthService,
    private readonly fb: FormBuilder,
    readonly title: Title
  ) {
    title.setTitle('Request Reset Password | Castle Bee');
  }

  /**
   * Initializes the component
   */
  ngOnInit(): void {
    this.buildForm();
  }

  /**
   * Checks and submits the reset password request form
   */
  onSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    }

    this.error = null;
    this.success = null;

    const data: RequestPasswordForm = this.form.value;
    this.authService
      .requestPasswordReset(data)
      .subscribe(
        () =>
          (this.success =
            'Check your emails and click on the link to reset your password'),
        (err: HttpErrorResponse) => (this.error = err)
      );
  }

  /**
   * Creates the request password form
   */
  private buildForm(): void {
    this.form = this.fb.group({
      email: [null, Validators.required]
    });
  }
}
