import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from './abstracts/api-base.service';

import { ContactForm } from '../models';


@Injectable({ providedIn: 'root' })
export class ContactService extends ApiBaseService {
  protected controllerName = 'contact';

  post(formData: ContactForm): Observable<void> {
    return this.httpClient.post<void>(this.getUrl(''), formData);
  }
}
