<div fxFlexFill fxLayoutAlign="center center">
  <cbws-form-card>
    <!-- Header -->
    <cbws-form-card-header>
      <h3 class="mat-headline mb-0 ml-5">Changer le mot de passe</h3>
      <img
        class="cbws-form-card-header-logo"
        src="assets/images/logo2.png"
      />
    </cbws-form-card-header>

    <!-- Content -->
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <cbws-form-card-content>
        <!-- Password -->
        <mat-form-field fxFlex="100%">
          <mat-label>Nouveau mot de passe</mat-label>
          <input
            type="password"
            matInput
            formControlName="password"
            placeholder="Password"
          />
          <mat-error *ngIf="password.invalid">
            <cbws-input-error
              [control]="password"
              label="Password"
            ></cbws-input-error>
          </mat-error>
        </mat-form-field>

        <!-- Password Confirmation -->
        <mat-form-field fxFlex="100%">
          <mat-label>Confirmation du mot de passe</mat-label>
          <input
            type="password"
            matInput
            formControlName="passwordConfirmation"
            placeholder="Password confirmation"
          />
          <mat-error *ngIf="passwordConfirmation.invalid">
            <cbws-input-error
              [control]="passwordConfirmation"
              label="Password confirmation"
            ></cbws-input-error>
          </mat-error>
        </mat-form-field>

        <!-- Errors -->
        <cbws-api-errors [error]="error" fxFlex="100%"></cbws-api-errors>

        <!-- Actions -->
        <cbws-form-card-action fxFlex="100%">
          <a [routerLink]="['/login']" class="btn btn-light" fxFlex>
            Annuler
          </a>
          <button type="submit" class="btn btn-primary" fxFlex>
            Changer
          </button>
        </cbws-form-card-action>
      </cbws-form-card-content>
    </form>
  </cbws-form-card>
</div>
