import { Component } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IconButtonBase } from 'src/app/models/abstracts/icon-button-base';

@Component({
  selector: 'app-icon-times-button',
  templateUrl: './icon-times-button.component.html'
})
export class IconTimesButtonComponent extends IconButtonBase {
  faTimes = faTimes;
}
