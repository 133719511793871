<mat-form-field class="app-autocomplete w-100" [appearance]="appearance">
  <mat-label>{{ label }}</mat-label>
  <input
    type="text"
    matInput
    [formControl]="innerCtrl"
    [placeholder]="placeholder || label"
  />
  <button
    class="app-autocomplete-reset"
    matSuffix
    mat-icon-button
    *ngIf="innerCtrl.value"
  >
    <fa-icon
      mat-icon-button
      matSuffix
      [icon]="faTimes"
      (click)="resetClick($event)"
    ></fa-icon>
    
  </button>
</mat-form-field>
