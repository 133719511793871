<header
  class="cbws-navbar"
  [class.cbws-header-sticky]="isSticky"
  [class.cbws-header-expanded]="isExpanded"
>
  <nav
    class="navbar navbar-expand-md p-0"
  >
    <div class="container">
      <a
        class="navbar-brand"
        routerLink="/administration"
        title="Retour à la page d'accueil"
        i18n-title="header home link|Link for the home page of the header"
      >
        <cbws-logo></cbws-logo>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target=".navbar-collapse"
        aria-label="Basculer la barre de navigation"
        i18n-aria-label="
          header collapse menu button|Aria label for the collapse menu button
        "
        [attr.aria-expanded]="isExpanded"
        (click)="toggle()"
      >
        <fa-icon [icon]="faBars"></fa-icon>
      </button>
      <div
        class="collapse navbar-collapse flex-md-row-reverse"
        [class.show]="isExpanded"
      >
        <ul class="navbar-nav flex-grow align-items-center">
          <!-- <li class="nav-item">
            <a class="nav-link p-2" title="Administration" [routerLink]="['/administration/']" routerLinkActive="active" >
              <fa-icon [icon]="faTools"></fa-icon>
            </a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link p-2" title="Edit profile" [routerLink]="['/administration/profil']" routerLinkActive="active" >
              <fa-icon [icon]="faUserEdit"></fa-icon>
            </a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link p-2" title="Priorities" [routerLink]="['/administration/priorities']" routerLinkActive="active" >
              <fa-icon [icon]="faUserEdit"></fa-icon>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link p-2" title="Meeting Types" [routerLink]="['/administration/meetingtype']" routerLinkActive="active" >
              <fa-icon [icon]="faUserEdit"></fa-icon>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link p-2" title="Meetings" [routerLink]="['/administration/meeting']" routerLinkActive="active" >
              <fa-icon [icon]="faTools"></fa-icon>
            </a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link p-2" title="Sign out" (click)="logout()">
              <fa-icon [icon]="faSignOutAlt"></fa-icon>
            </a>
          </li>
        </ul>
      </div>
      
    </div>
  </nav>
</header>
