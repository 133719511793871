<span *ngIf="control.hasError('required')">{{ label }} est requis</span>
<span *ngIf="control.hasError('match')">{{ label }} do not match</span>
<span *ngIf="control.hasError('min')">
  {{ label }} must be greater than or equal to {{ control.errors['min'].min }}
</span>
<span *ngIf="control.hasError('max')">
  {{ label }} must be lower than or equal to {{ control.errors['max'].max }}
</span>
<span *ngIf="control.hasError('range')">
  {{ label }} must be between {{ control.errors['range'].min }} and
  {{ control.errors['range'].max }}
</span>
<span *ngIf="control.hasError('greaterThan')">
  {{ label }} must be greater than {{ control.errors['greaterThan'].min }}
</span>
<span *ngIf="control.hasError('year')">{{ label }} is not a valid year</span>
<span *ngIf="control.hasError('unique')">
  {{ control.errors['unique'].value }} already exists
</span>
<span *ngIf="control.hasError('requireAnswer')">
  You must select an answer to this question
</span>
