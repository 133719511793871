import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NEVER, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  /**
   * HttpErrorInterceptor constructor
   * @param authService authentication service
   * @param router Angular's router service
   */
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  /**
   * Intercepts every API request error and redirect to login page when 401 is returned
   * @param request the current request
   * @param next the request handler
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf('api/') < 0) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        // Handles errors
        switch (err.status) {
          case 401:
            this.logout();
            break;
          case 469:
            this.enable2fa();
            break;
          default:
            return throwError(err);
        }

        return NEVER;
      })
    );
  }

  /**
   * Redirects user to enable authenticator
   */
  private enable2fa(): void {
    this.router.navigate(['/security/authenticator/enable']);
  }

  /**
   * Logs user out and reload page
   */
  private logout(): void {
    this.authService.logout();
    location.reload();
  }
}
