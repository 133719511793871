import { Component } from '@angular/core';
import { PreviousRouteService } from 'src/app/services';


@Component({
  selector: 'cbws-root',
  templateUrl: './root.component.html'
})
export class RootComponent {
  /**
   * RootComponent constructor
   * @param previousRouteService previous route service
   */
  constructor(private readonly previousRouteService: PreviousRouteService) {}
}
