import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { RoleType } from '../enums/role-type.enum';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AdminGuard implements CanActivate {
  /**
   * AdminGuard constructor
   * @param authService authentication service
   * @param router Angular's router
   */
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  /**
   * Checks if user has administrator role
   * @param route Angular's current route
   * @param state Angular's router state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (
      this.authService.user.role != null &&
      this.authService.user.role === RoleType.administrator
    ) {
      return true;
    }

    let returnUrl = '/';
    if (state.url.includes('administration')) {
      returnUrl = '/administration/';
    }

    return this.router.parseUrl(returnUrl);
  }
}
