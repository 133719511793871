
<div class="container">
  <div class="row justify-content-center" style="padding-top: 100px;">
    <img src="/assets/images/logo2.png" height="400px"/>
  </div>
  <div class="row justify-content-center">
    <p style="font-weight: bolder;">Nos abeilles sont occupées à construire notre ruche.</p>
  </div>
  <div class="row justify-content-center">
    <p>Restez à l'affût, cette page sera bientôt prête.</p>
  </div>
</div>