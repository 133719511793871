<footer class="cbws-footer fixed-bottom text-center">
  <div class="cbws-footer-container">
    <div class="container">
      <div class="justify-content-center">
<p>
          <cbws-copyright class="mr-3"></cbws-copyright> 
          -
          <span class="cbws-footer-links ml-3" *ngFor="let social of socials"> 
            <a class="cbws-footer-link" [href]="social.url" target="_blank" [title]="social.title">
              <fa-icon [icon]="social.icon"></fa-icon>
            </a>
          </span>
          -
          <a 
            class="cbws-footer-link"
            routerLink="/home/cookies"
            target="_blank">
            Politique en matière de cookies
          </a>
          -
          <a 
            class="cbws-footer-link"
            routerLink="/home/legalnotice"
            target="_blank">
            Mentions légales
          </a>
        </p>
      </div>
    </div>
  </div>
</footer>
