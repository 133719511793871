import { Component, Input } from '@angular/core';

@Component({
  selector: 'cbws-table-empty',
  templateUrl: './table-empty.component.html'
})
export class TableEmptyComponent {
  /**
   * Data source used in table
   */
  @Input()
  dataSource: Array<any>;
}
