import { Injectable } from '@angular/core';

@Injectable()
export class FilterService {
  /**
   * Time to wait before emitting values
   */
  debounceTime = 500;

  /**
   * Compares the provided values and returns if they are the same
   * @param a first value
   * @param b second value
   * @returns the flag indicating if the values are considered the same
   */
  compareValues(a: any, b: any): boolean {
    if (!a || !b) {
      return false;
    }

    for (const [key, value] of Object.entries(a)) {
      const bValue = b[key];
      if (!value && !bValue) {
        continue;
      }

      if (value !== bValue) {
        return false;
      }
    }

    return true;
  }
}
