import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { RoleType } from 'src/app/enums/role-type.enum';
import { LoginFormViewBase } from 'src/app/models';
import { UserRecoveryCodeAuth } from 'src/app/models/login/user-recovery-code-auth';
import { AuthService } from 'src/app/services/auth.service';



@Component({
  selector: 'cbws-login-recovery-code',
  templateUrl: './login-recovery-code.component.html'
})
export class LoginRecoveryCodeComponent
  extends LoginFormViewBase<UserRecoveryCodeAuth>
  implements OnInit, OnDestroy {
  /**
   * Gets the username form control
   */
  get code(): AbstractControl {
    return this.form.get('code');
  }

  /**
   * LoginRecoveryCodeComponent constructor
   * @param authService authentication service
   * @param fb Angular's form builder
   * @param route Angular's current route
   * @param router Angular's router service
   * @param title Angular's title service
   */
  constructor(
    private readonly authService: AuthService,
    private readonly fb: FormBuilder,
    readonly route: ActivatedRoute,
    private readonly router: Router,
    readonly title: Title
  ) {
    super(route, title, 'Recovery Code Authentication');
  }

  /**
   * Initializes the component
   */
  ngOnInit(): void {
    this.init();
  }

  /**
   * Completes all subscriptions
   */
  ngOnDestroy(): void {
    this.ngOnDestroy();
  }

  /**
   * Cancels authentication
   */
  onCancel(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  /**
   * Checks and submits the recovery code
   */
  onLogin(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    }

    this.resetBeforeRequest();
    const code = this.getFormData();

    this.authService
      .loginRecoveryCode(code)
      .pipe(finalize(() => this.finalize()))
      .subscribe(
        () => {
          if (this.returnUrl != null) {
            if (
              this.authService.role === RoleType.administrator &&
              !this.returnUrl.includes('administration')
            ) {
              this.router.navigate(['administration']);
            } else {
              this.router.navigateByUrl(this.returnUrl);
            }
          } else {
            if (this.authService.role === RoleType.administrator) {
              this.router.navigate(['administration']);
            } else {
              this.router.navigate(['home']);
            }
          }
        },
        (err: HttpErrorResponse) => this.catchError(err)
      );
  }

  /**
   * Creates the login forms
   */
  protected buildForm(): void {
    this.form = this.fb.group({
      code: [null, Validators.required]
    });
  }
}
