import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { takeUntil } from 'rxjs/operators';
import { Disposable } from 'src/app/components/core/disposable';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class FilterBase<T>
  extends Disposable
  implements ControlValueAccessor, OnInit, OnDestroy {
  /**
   * Form field appearance
   */
  @Input()
  appearance: MatFormFieldAppearance = 'outline';
  /**
   * Label to be displayed for the input
   */
  @Input()
  label: string;
  /**
   * Placeholder of the input
   */
  @Input()
  placeholder: string;

  /**
   * Inner control used for text display
   */
  innerCtrl: FormControl = new FormControl();

  /**
   * Function used to notify parent that the value has changed
   */
  @HostListener('change', ['$event.target.value'])
  onChange: (value: T) => void = (_: T) => { };
  /**
   * Function used to notify parent that the control has been touched
   */
  @HostListener('blur')
  onTouched: () => void = () => { };

  /**
   * Initializes the component
   */
  ngOnInit(): void {
    this.innerCtrl.valueChanges
      .pipe(takeUntil(this.sub$))
      .subscribe((value: T) => this.onChange(value));
  }

  /**
   * Registers the function to be used to notify parent on value change
   * @param fn the function to be used to notify parent on value change
   */
  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  /**
   * Registers the function to be used to notify parent on control touched
   * @param fn the function to be used to notify parent on control touched
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Resets the control to its default value
   * @param event click event
   */
  resetClick(event: MouseEvent): void {
    if (!event.defaultPrevented) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.innerCtrl.reset();
  }

  /**
   * Writes the value into this control
   * @param value the external value to be set
   */
  writeValue(value: T): void {
    this.onChange(value);
    this.innerCtrl.patchValue(value);
  }
}
