import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  /**
   * AuthGuard constructor
   * @param authService Authentication service
   * @param router Angular's router
   */
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  /**
   * Checks if the route can be activated
   * @param route Angular's current route
   * @param state Angular's current router state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (!this.authService.user) {
      return this.router.parseUrl(`/login?returnUrl=${state.url}`);
    }
    return true;
  }

  /**
   * Checks if the route children can be activated
   * @param route Angular's current route
   * @param state Angular's current router state
   */
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    return this.canActivate(route, state);
  }
}
