import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ApiError } from 'src/app/models';


@Component({
  selector: 'cbws-api-errors',
  templateUrl: './api-errors.component.html'
})
export class ApiErrorsComponent {
  /**
   * Returned error response from API
   */
  @Input()
  set error(err: any) {
    if (err == null) {
      this.errors = [];
      return;
    }

    if (err instanceof HttpErrorResponse) {
      this.error = err.error; // ERROR is Array<ApiError> or ApiError
    } else {
      if (Array.isArray(err)) {
        this.errors = err.slice();
      } else {
        this.errors = [err];
      }
    }
  }

  /**
   * Errors to display
   */
  errors: Array<ApiError>;
}
