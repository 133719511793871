import { Component } from '@angular/core';
import { IconButtonBase } from 'src/app/models/abstracts/icon-button-base';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

  @Component({
  selector: 'cbws-icon-edit-button',
  templateUrl: './icon-edit-button.component.html'
})
export class IconEditButtonComponent extends IconButtonBase {
  faEdit = faEdit;
}
