<div
  class="cbws-loading-spinner"
  fxLayout="column"
  fxLayoutAlign="center center"
  [style.height]="maxHeight"
>
  <mat-spinner
    [diameter]="diameter"
    [color]="color"
    [mode]="mode"
  ></mat-spinner>
</div>
