<cbws-login-form-card [requesting]="requesting">
  <form [formGroup]="form" (ngSubmit)="onLogin()">
    <cbws-form-card-content>
      <p fxFlex="100%">
        You have requested to log in with a recovery code. This login will not
        be remembered until you provide an authenticator app code at log in or
        disable 2FA and log in again.
      </p>

      <!-- Code -->
      <mat-form-field fxFlex="100%">
        <mat-label>Recovery code</mat-label>
        <input
          type="text"
          autocomplete="off"
          autofocus
          matInput
          formControlName="code"
          placeholder="Enter recovery code"
        />
        <mat-error *ngIf="code.invalid">
          <cbws-input-error
            [control]="code"
            label="Recovery code"
          ></cbws-input-error>
        </mat-error>
      </mat-form-field>

      <!-- Errors -->
      <cbws-api-errors [error]="error" fxFlex="100%"></cbws-api-errors>

      <!-- Actions -->
      <cbws-form-card-action fxFlex="100%">
        <button
          type="button"
          mat-button
          [disabled]="requesting"
          (click)="onCancel()"
          fxFlex
        >
          Cancel
        </button>
        <button
          type="submit"
          class="sign-in-button"
          mat-flat-button
          color="primary"
          [disabled]="requesting"
          fxFlex
        >
          Sign in
        </button>
      </cbws-form-card-action>
    </cbws-form-card-content>
  </form>
</cbws-login-form-card>
