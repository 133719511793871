import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Disposable } from 'src/app/components/core';
import { ResetPasswordForm } from 'src/app/models';
import { AuthService } from 'src/app/services/auth.service';



@Component({
  selector: 'cbws-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent extends Disposable
  implements OnInit, OnDestroy {
  /**
   * The login form
   */
  form: FormGroup;
  /**
   * HTTP error response
   */
  error: HttpErrorResponse;

  /**
   * Gets the password form control
   */
  get password(): AbstractControl {
    return this.form.get('password');
  }
  /**
   * Gets the password confirmation form control
   */
  get passwordConfirmation(): AbstractControl {
    return this.form.get('passwordConfirmation');
  }

  /**
   * ResetPasswordComponent constructor
   * @param authService Authentication service
   * @param fb Angular's form builder
   * @param route Angular's current route
   * @param router Angular's router
   * @param title Angular's title service
   */
  constructor(
    private readonly authService: AuthService,
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    readonly title: Title
  ) {
    super();

    title.setTitle('Reset Password | Castle Bee');
  }

  /**
   * Initializes the component
   */
  ngOnInit(): void {
    this.buildForm();

    // Get user id and reset token from url
    this.route.queryParamMap
      .pipe(takeUntil(this.sub$))
      .subscribe((paramMap: ParamMap) => {
        this.form.get('id').patchValue(parseInt(paramMap.get('user')));
        this.form.get('token').patchValue(paramMap.get('token'));
      });
  }

  /**
   * Checks and submits the reset password form
   */
  onSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    }

    this.error = null;

    const data: ResetPasswordForm = this.form.value;
    this.authService
      .resetPassword(data)
      .subscribe(
        () => this.router.navigate(['/login']),
        (err: HttpErrorResponse) => (this.error = err)
      );
  }

  /**
   * Creates the reset password form
   */
  private buildForm(): void {
    this.form = this.fb.group({
      id: [null],
      token: [null],
      password: [null, Validators.required],
      passwordConfirmation: [null, Validators.required]
    });
  }
}
