import { Component } from '@angular/core';
import { SpinnerBaseComponent } from '../../core/spinner-base.component';


@Component({
  selector: 'cbws-table-spinner',
  templateUrl: './table-spinner.component.html'
})
export class TableSpinnerComponent extends SpinnerBaseComponent {
}
